<div *ngIf="justificatif$ | async as justificatif; else loading">

    <form (ngSubmit)="saveForm()" [formGroup]="reclamForm">
        <div class="card border-light rounded-1 mt-4">
            <div class="card-body">
                <h5 class="card-title text-secondary">Rendre éligible</h5>
                <div class="p-2">
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <label for="marchand">Nom de l’enseigne ou du site internet<small>*</small></label>
                            <input class="form-control" formControlName="marchand" id="marchand" type="text">
                            <val-errors controlName="marchand"></val-errors>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <label for="reclamation">Precisez votre demande<small>*</small></label>
                            <textarea class="form-control" formControlName="reclamation" id="reclamation"
                                      rows="3"></textarea>
                            <val-errors controlName="reclamation"></val-errors>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="refundEnabled" class="form-row">
            <div class="form-group col-md-12">
                <div>
                    <p class="pt-3">Vous avez réglé cette dépense avec un autre moyen de paiement ? Demander le
                        remboursement.</p>
                    <mcv-toggle (onValueChange)="toggleRefundRequest($event)" [value]="refundRequestShow">
                        Demande de remboursement
                    </mcv-toggle>
                </div>
                <div *ngIf="refundRequestShow;">
                    <mcv-refund-request-form [cseEmploye]="cseEmploye"
                                             [reclamationForm]="reclamForm"></mcv-refund-request-form>
                </div>
            </div>
        </div>

        <div *ngIf="!refundRequestShow" class="text-center">
            <button [disabled]="!reclamForm.valid || sending"
                    [promiseBtn]="sending"
                    class="btn btn-primary rounded-pill pl-5 pr-5"
                    type="submit">
                Envoyer
            </button>
        </div>
    </form>
</div>
<ng-template #loading>
    <mcv-loading></mcv-loading>
</ng-template>
