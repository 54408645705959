<nav class="container-fluid bg-white d-block d-sm-none px-1 pt-2 fixed-top">
    <div class="d-flex align-items-center">
        <div class="col-8">
            <img class="img-fluid" src="{{baseUrl}}/img/logo.png"/>
        </div>
        <div class="col text-right pr-2 mr-1">
            <a [routerLinkActive]="'active text-primary-alt'"
               [routerLink]="'/client/profile'"
               class="nav-link p-0">
                <i class="far fa-fw fa-user"></i> Profil
            </a>
        </div>
    </div>
    <ul class="nav nav-tabs nav-justified pt-2" style="font-size: 0.90rem;">
        <li class="nav-item">
            <a [routerLinkActive]="'active text-primary-alt'"
               [routerLink]="'/client/transaction/index'"
               class="nav-link px-2">
                <i class="far fa-fw fa-wallet"></i> Compte
            </a>
        </li>
        <li class="nav-item">
            <a [routerLinkActive]="'active text-primary-alt'"
               [routerLink]="'/client/carte'"
               class="nav-link px-2">
                <i class="far fa-fw fa-credit-card"></i> Carte
            </a>
        </li>
        <li class="nav-item">
            <a [routerLinkActive]="'active text-primary-alt'"
               [routerLink]="'/client/catalogue'"
               class="nav-link px-1">
                <i class="far fa-fw fa-map-marker-smile"></i> Où l'utiliser
            </a>
        </li>
        <li *ngIf="this.authService.user.cse.is_promotion_enabled" class="nav-item">
            <a (click)="promotionRedirect()" class="nav-link px-1 cursor-pointer">
                <i class="far fa-fw fa-ticket"></i>
                Billetterie
            </a>
        </li>
        <li class="nav-item">
            <a [routerLinkActive]="'active text-primary-alt'"
               [routerLink]="'/client/activite'"
               class="nav-link px-1">
                <i class="far fa-fw fa-table-tennis"></i> Activités locales
            </a>
        </li>
    </ul>
</nav>
<div class="d-none d-sm-block">
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary fixed-top">
        <a class="navbar-brand" href="/" style="font-size: 1.5rem;">
            <span class="align-text-top">macartevacances</span>
        </a>

        <button (click)="collapse.toggle()"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                class="navbar-toggler"
                data-target="#navbarSupportedContent"
                data-toggle="collapse"
                type="button">
            <span class="navbar-toggler-icon"></span>
        </button>

        <ngx-navbar-collapse #collapse="ngxNavbarCollapse" id="main-nav">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item">
                    <a [routerLink]="'/client/compte'" class="nav-link" routerLinkActive="active">
                        <i class="far fa-fw fa-wallet"></i>&nbsp;<ng-container>Compte</ng-container>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="'/client/carte'" class="nav-link" routerLinkActive="active">
                        <i class="far fa-fw fa-credit-card"></i>&nbsp;<ng-container>Carte</ng-container>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="'/client/catalogue'" class="nav-link" routerLinkActive="active">
                        <i class="far fa-fw fa-map-marker-smile"></i>&nbsp;<ng-container>Où l'utiliser</ng-container>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="'/client/profile'" class="nav-link" routerLinkActive="active">
                        <i class="far fa-fw fa-user"></i>&nbsp;<ng-container>Profil</ng-container>
                    </a>
                </li>
                <li *ngIf="this.authService.user.cse.is_promotion_enabled" class="nav-item">
                    <a (click)="promotionRedirect()" class="nav-link cursor-pointer">
                        <i class="far fa-fw fa-ticket"></i>&nbsp;<ng-container>Billetterie</ng-container>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="'/client/activite'" class="nav-link" routerLinkActive="active">
                        <i class="far fa-fw fa-table-tennis"></i>&nbsp;<ng-container>Activités locales</ng-container>
                    </a>
                </li>
            </ul>
            <ul class="navbar-nav float-right">
                <li class="nav-item">
                    <a [routerLink]="'/auth/logout'" class="nav-link">
                        <i aria-hidden="true" class="far fa-sign-out-alt"></i>&nbsp;<ng-container i18n>Se déconnecter
                    </ng-container>
                    </a>
                </li>
            </ul>
        </ngx-navbar-collapse>
    </nav>
</div>
