import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@cseemploye/app/auth/guards/auth.guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'client',
        pathMatch: 'full'
    },
    {
        path: 'justificatif/:id',
        redirectTo: '/activation/aide/justificatif/:id',
        pathMatch: 'full'
    },
    {
        path: 'client',
        canActivate: [AuthGuard],
        loadChildren: () => import('./client/client.module').then(m => m.ClientModule)
    },
    {
        path: 'activation',
        loadChildren: () => import('./activation/activation.module').then(m => m.ActivationModule)
    },
    {
        path: 'card',
        loadChildren: () => import('./card/card.module').then(m => m.CardModule)
    },
    {
        path: 'pdf',
        loadChildren: () => import('./pdf/pdf.module').then(m => m.PdfModule)
    },
    {
        path: 'mobile-check',
        loadChildren: () => import('./mobilecheck/mobilecheck.module').then(m => m.MobilecheckModule)
    },
    {
        path: '**', redirectTo: '/client/compte/index'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: false, relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})

export class AppRoutingModule {
}
