import {Injectable} from '@angular/core';
import {environment} from '@cseemploye/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SessionStorageService {
    private prefix = `mcv_preboarding_${environment.client_env}_`;
    private storage = window.sessionStorage;

    constructor() {
    }

    public save(key: string, value: any) {
        this.storage.setItem(this.prefix + key, JSON.stringify(value));
    }

    public read(key: string) {
        const data = this.storage.getItem(this.prefix + key);
        if (data) {
            return JSON.parse(data);
        }
        return null;
    }

    public exists(key: string) {
        return !!this.storage.getItem(this.prefix + key);
    }

    public clear() {
        this.storage.clear();
    }
}
