import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {BASEURL} from '@mcv/config';

@Component({
    selector: 'mcv-step2',
    templateUrl: './step2.component.html',
    styleUrls: ['./step2.component.scss']
})
export class Step2Component implements OnInit {
    @Output() nextStep: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(@Inject(BASEURL) public baseUrl: string) {
    }

    ngOnInit(): void {
    }

}
