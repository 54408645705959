import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'toDateObj'
})
export class ToDateObjPipe implements PipeTransform {

    transform(value: unknown, ...args: unknown[]): Date {
        if (value) {
            const temp = value.toString().replace(' ', 'T');
            return new Date(temp);
        } else {
            return null;
        }
    }
}
