import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NewReclamationComponent } from '@cseemploye/app/activation/pages/new-reclamation/new-reclamation.component';
import {
    ReclamationWithoutTransactionComponent
} from '@cseemploye/app/activation/pages/reclamation-without-transaction/reclamation-without-transaction.component';
import { ReclamationComponent } from '@cseemploye/app/activation/pages/reclamation/reclamation.component';
import { CatalogComponent } from '@cseemploye/app/shared/pages/catalog/catalog.component';
import { AuthGuard } from '../auth/guards/auth.guard';
import { AccountComponent } from '../compte/pages/account/account.component';
import { ProfileComponent } from '../profile/pages/profile.component';
import { IndexComponent } from './pages/index/index.component';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: '/client/compte/index'
    },
    {
        path: '',
        component: IndexComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'compte',
                loadChildren: () => import('../compte/compte.module').then(m => m.CompteModule)
            },
            {
                path: 'transaction',
                loadChildren: () => import('../rttransaction/rttransaction.module').then(m => m.RttransactionModule)
            },
            {
                path: 'carte',
                loadChildren: () => import('../carte/carte.module').then(m => m.CarteModule)
            },
            {
                path: 'profile',
                loadChildren: () => import('../profile/profile.module').then(m => m.ProfileModule)
            },
            {
                path: 'contribution',
                loadChildren: () => import('../contribution/contribution.module').then(m => m.ContributionModule)
            },
            {
                path: 'catalogue',
                component: CatalogComponent
            },
            {
                path: 'activite',
                loadChildren: () => import('../activite/activite.module').then(m => m.ActiviteModule)
            },
            {
                path: 'confirm-email-alt-code',
                component: ProfileComponent
            },
            {
                path: 'detail',
                component: AccountComponent,
                data: { view: 'detail' }
            },
            {
                path: 'reclamation',
                component: ReclamationWithoutTransactionComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ClientRoutingModule {
}
