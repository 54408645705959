import {Pipe, PipeTransform} from '@angular/core';
import {Transaction} from '@mcv/core';

@Pipe({
    name: 'nTransaction'
})
export class NTransactionPipe implements PipeTransform {

    transform(value: Transaction[], ...args: string[]): Transaction {
        if (!args || args.length === 0) {
            return this.returnFirst(value);
        }
        switch (args[0]) {
        case 'last':
            return this.returnLast(value);
        case 'first':
        default:
            return this.returnFirst(value);
        }
    }

    private returnFirst(value: Transaction[]): Transaction {
        return value.length > 0 ? value[0] : null;
    }

    private returnLast(value: Transaction[]): Transaction {
        return value.length > 0 ? value[value.length - 1] : null;
    }
}
