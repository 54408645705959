import {Component, OnInit} from '@angular/core';
import {AuthService} from '@cseemploye/app/auth/services/auth.service';
import {environment} from '@cseemploye/environments/environment';
import {Intercom} from 'ng-intercom';

@Component({
    selector: 'mcv-root',
    template: `
        <div class="main-container">
            <router-outlet></router-outlet>
        </div>
        <val-default-errors>
            <ng-template valError="required" let-label>
                <span data-cy="requirederror">Ce champ est requis</span>
            </ng-template>
            <ng-template valError="email" let-label>
                <span data-cy="emailerror">L'adresse email saisie n'est pas valide</span>
            </ng-template>
            <ng-template valError="minlength" let-error="error" let-label>
                <span data-cy="minlengtherror">{{ label || 'Ce champ' }}
                    doit comporter au moins {{ error.requiredLength }} caractères</span>
            </ng-template>
            <ng-template valError="pattern" let-error="error" let-label>
                <span data-cy="patternerror">{{ label || 'Ce champ' }} ne respecte pas les règles énoncées</span>
            </ng-template>
            <ng-template valError="notSame" let-error="error" let-label>
                Les valeurs ne correspondent pas
            </ng-template>
        </val-default-errors>
        <div class="notifier">
            <notifier-container></notifier-container>
        </div>
        <div id="intercom"></div>
    `,
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    private isAdmin = false;

    constructor(private intercom: Intercom,
                private authService: AuthService) {
    }

    ngOnInit(): void {
        this.isAdmin = this.authService.superAdmin;
        const isInApp = window.location.href.indexOf('_inapp=1') > 0;
        if (this.isAdmin || isInApp) {
            this.intercom.shutdown();
        } else {
            this.intercom.boot({
                app_id: environment.INTERCOM,
                widget: {
                    'activator': '#intercom'
                }
            });
        }
    }

}
