import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'monthYearName'
})
export class MonthYearNamePipe implements PipeTransform {
    monthNames = [
        'janvier',
        'février',
        'mars',
        'avril',
        'mai',
        'juin',
        'juillet',
        'aout',
        'septembre',
        'octobre',
        'novembre',
        'décembre'
    ];

    transform(value: string, ...args: unknown[]): string {
        const date = new Date(value);
        return this.monthNames[date.getMonth()] + ' ' + date.getFullYear();
    }

}
