import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Transaction} from '@mcv/core';

@Component({
    selector: 'mcv-transaction-list',
    templateUrl: './transaction-list.component.html',
    styleUrls: ['./transaction-list.component.scss']
})
export class TransactionListComponent implements OnInit {
    @Input() count: number;
    @Input() itemsPerPage: number;
    @Input() transactions: Transaction[];
    @Output() changePage: EventEmitter<number> = new EventEmitter<number>();

    constructor() {
    }

    ngOnInit(): void {
    }

    sort(): number {
        return 0;
    }
}
