<ng-container *ngIf="transaction.cse_employe_justificatif_required">
    <ng-container *ngIf="showInvalid">
        <div class="text-danger mr-2" style="flex: 2">{{transaction.cse_employe_justificatif?.state_comment}}</div>
    </ng-container>
    <ng-container *ngIf="showUpload">
        <mcv-justificatif-button-upload (sent)="setJustificatif($event)"
                                        [cse_employe_public_id]="transaction.cse_employe_id"
                                        [publicId]="transaction.cse_employe_justificatif?.public_id"
                                        [publicPaymentId]="transaction.publicPaymentId"
        >
        </mcv-justificatif-button-upload>
    </ng-container>
    <ng-container *ngIf="showInProgress">
        <span class="btn btn-sm btn-warning" data-cy="state_new" tooltip="{{'Justificatif en cours de validation'}}">
            <i class="far fa-check-circle fa-fw"></i>
        </span>
    </ng-container>
    <ng-container *ngIf="showValid">
        <span class="btn btn-sm btn-success" data-cy="state_ok" tooltip="{{'Justificatif validé'}}">
            <i class="far fa-check-circle fa-fw"></i>
        </span>
    </ng-container>
</ng-container>
