import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '@cseemploye/app/auth/services/auth.service';
import { environment } from '@cseemploye/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PromotionService {

    constructor(
        protected httpClient: HttpClient,
        private authService: AuthService
    ) {}

    redirectInfos() {
        return this.httpClient.post(
            `${environment.baseUrl}/cse-employe/cse-employe/redirectInfos`, {}
        ).toPromise();
    }

    redirect(authkey: string, subdomain: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', `Bearer ${authkey}`);

        return this.httpClient.post(
            `${environment.promotionBaseUrl}/redirect`, { authkey, subdomain }, { headers }
        ).toPromise();
    }
}
