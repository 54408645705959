import {Pipe, PipeTransform} from '@angular/core';
import {Transaction} from '@mcv/core';

@Pipe({
    name: 'groupByMonthYear'
})
export class GroupByMonthYearPipe implements PipeTransform {

    transform(value: Transaction[], ...args: unknown[]): Record<string, Transaction[]> {
        const months: Record<string, Transaction[]> = {};

        value.forEach(t => {
            const tm = new Date(t.tm);
            const dateIndice = tm.getFullYear() + '-' + this.pad(tm.getMonth() + 1) + '-01';
            if (!months[dateIndice]) {
                months[dateIndice] = [];
            }
            months[dateIndice].push(t);
        });
        return months;
    }

    private pad(value: number): string {
        return value.toString()
            .padStart(2, '0');
    }
}
