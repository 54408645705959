import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {BASEURL} from '@mcv/config';

@Component({
    selector: 'mcv-step4',
    templateUrl: './step4.component.html',
    styleUrls: ['./step4.component.scss']
})
export class Step4Component implements OnInit {
    @Output() nextStep: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(@Inject(BASEURL) public baseUrl: string) {
    }

    ngOnInit(): void {
    }

}
