import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ConfigurationsService } from '@cseemploye/app/auth/services/configurations.service';
import { CreateForms, CseEmploye } from '@mcv/core';
import { CseEmployeService } from '@mcv/coreservices';
import { McvnotifierService } from '@mcv/ui';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'mcv-profile-personal',
    templateUrl: './profile-personal.component.html',
    styleUrls: ['./profile-personal.component.scss']
})
export class ProfilePersonalComponent implements OnInit {
    @Input() cseEmploye: CseEmploye;

    persoForm: FormGroup = CreateForms(this.fb);
    saving = false;

    modalRef: BsModalRef;
    confirmPasswordForm: FormGroup = this.fb.group({
        public_id: [],
        password: []
    });

    constructor(private fb: FormBuilder,
                private notifierService: McvnotifierService,
                private configurationService: ConfigurationsService,
                private modalService: BsModalService,
                private cseEmployeService: CseEmployeService) {
        this.persoForm.removeControl('birthday');
    }

    ngOnInit(): void {
        this.persoForm.patchValue(this.cseEmploye);
        this.persoForm.addControl('password_check', new FormControl([]));
    }

    async saveForm(): Promise<void> {
        try {
            this.saving = true;
            await this.cseEmployeService.add(this.persoForm.value)
                .pipe(
                    switchMap(_ => this.configurationService.loadAppConfig())
                )
                .toPromise();
            this.notifierService.success('Vos informations ont été enregistrées');
        } catch (e) {
            // 403 error already catch by the interceptor
            if (e.status !== 403) {
                this.notifierService.error(e);
            }
            this.ngOnInit();
        } finally {
            this.saving = false;
        }
    }

    nosort(_): number {
        return 0;
    }

    checkPassword(confirmPasswordModale: TemplateRef<any>): void {
        this.modalRef = this.modalService.show(confirmPasswordModale, { class: 'modal-dialog-centered modal-lg' });
    }

    confirmPassword(): void {
        this.persoForm.patchValue({
            password_check: this.confirmPasswordForm.value.password
        });
        this.saveForm();
        this.hide();
    }

    hide(): void {
        this.confirmPasswordForm.patchValue({ password: '' });
        this.modalRef.hide();
    }
}
