<div class="card border-light rounded-1 mt-4">
    <div class="card-body">
        <h5 class="card-title text-secondary">A propos</h5>
        <p *ngIf="cgu$ | async as cgu">
            <a href="{{baseUrl+'/contrat/'+cgu.version}}"
               target="_blank">
                Conditions générales d'utilisation
            </a>
        </p>
        <p><a href="{{MENTIONS_MACARTEVACANCES}}" target="_blank">Mentions légales</a></p>
        <p><a href="{{POLITIQUE_MACARTEVACANCES}}" target="_blank">Politique de confidentialité</a></p>
        <p><a (click)="showCarousel()" [routerLink]="">Guide d'utilisation</a></p>
        <div class="form-group mt-4 text-justify">
            <small>** macartevacances pourra mettra en œuvre des opérations promotionnelles adaptées à vos
                centres d’intérêt et besoins spécifiques, réalisées sur la base d’opérations de profilage,
                macartevacances ne sera amené à partager vos données avec des prestataires tiers que pour
                déterminer votre profil et vos préférences avec une précision accrue et ainsi vous envoyer
                des
                offres promotionnelles plus pertinentes. Veuillez toutefois noter qu’en aucun cas
                macartevacances ne communiquera vos données à des partenaires commerciaux.</small>
        </div>
        <div class="text-right w-100">
            <small>Application Version : {{version.default.version}}</small>
        </div>
    </div>
</div>
