<div class="container-fluid p-0 h-100" style="overflow: auto">

    <div class="d-flex flex-column h-100">

        <div class="flex-grow-0 mb-2">
            <div class="ml-2 mr-2">
                <div class="text-center text--title__font">
                    Où utiliser ma carte ?
                </div>
            </div>
        </div>

        <div class="flex-fill">
            <div class="d-flex flex-column ml-5 mr-5">
                <div class="text-center text--subtitle__font pb-2">
                    Magasin
                </div>
                <img class="illustration-page-2 img-fluid align-self-center"
                     src="{{baseUrl}}/img/carousel/magasin_illustration.png"/>
            </div>
        </div>

        <div class="flex-fill">
            <div class="d-flex flex-column ml-5 mr-5">
                <div class="text-center text--subtitle__font pb-2">
                    Internet
                </div>
                <img class="illustration-page-2 img-fluid align-self-center"
                     src="{{baseUrl}}/img/carousel/shopping_illustration.png"/>
            </div>
        </div>

        <div class="d-flex flex-fill">
            <div class="text-center text--subtitle__font flex-fill">
                En France et à l’international
            </div>
        </div>
    </div>
</div>
