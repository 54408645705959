import {EventEmitter, Injectable} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {HttpParams} from '@angular/common/http';
import {BehaviorSubject, combineLatest, Observable, of, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, startWith, switchMap, tap} from 'rxjs/operators';
import {FormControl, FormGroup} from '@angular/forms';
import {getDescendantProp, Result} from '@mcv/core';

@Injectable({
    providedIn: 'root'
})
export class PagingService {
    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    criteria: Map<string, any> = new Map();
    reset$: Subject<boolean> = new BehaviorSubject<boolean>(true);
    keepUrl = false;
    pageNumber = 1;
    limit = 10;
    totalElements: number;

    onPageChanged$: EventEmitter<number> = new EventEmitter<number>();

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute) {
        this.limit = Math.min(20, Math.round(window.innerHeight / 75));
        this.onPageChanged$.subscribe(_ => this.updateUrl());
    }

    get QueryParams(): any {
        return {
            page: this.pageNumber,
            limit: this.limit
        };
    }

    get httpParams(): HttpParams {
        let httpParams: HttpParams = new HttpParams();
        this.criteria.forEach((value: any, key: string) => {
            httpParams = httpParams.set(key, value);
        });
        httpParams = httpParams.set('page', (this.pageNumber).toString())
            .set('limit', this.limit.toString());
        return httpParams;
    }

    get queryParams(): any {
        const queryParams = {};
        this.criteria.forEach((value, key) => (queryParams[key] = value));
        return {...queryParams, ...this.QueryParams};
    }

    addCriteria(key: string, value: any): PagingService {
        this.criteria.set(key, value);
        return this;
    }

    removeCriteria(key: string): PagingService {
        if (this.criteria.has(key)) {
            this.criteria.delete(key);
        }
        return this;
    }

    updateUrl(): void {
        if (this.keepUrl) {
            this.router.navigate([], {
                relativeTo: this.activatedRoute,
                queryParams: this.queryParams
            });
        }
    }

    getRouteParams(criterias: string[] = []): Record<string, any> {
        const params: ParamMap = this.activatedRoute.snapshot.queryParamMap;
        this.pageNumber = params.has('page') ? +params.get('page') : 1;
        if (params.has('limit')) {
            this.limit = +params.get('limit');
        }
        criterias.forEach(criteria => {
            if (params.has(criteria)) {
                const value = (criteria.includes('_id') || criteria === 'id') ? +params.get(criteria) : params.get(criteria);
                this.addCriteria(criteria, value);
            }
        });
        return this.queryParams;
    }

    initRouteListener(criterias: string[] = []): Observable<any> {
        return this.activatedRoute
            .queryParamMap
            .pipe(
                map(params => {
                    this.pageNumber = params.has('page') ? +params.get('page') : 1;
                    if (params.has('limit')) {
                        this.limit = +params.get('limit');
                    }
                    criterias.forEach(criteria => {
                        if (params.has(criteria)) {
                            this.addCriteria(criteria, params.get(criteria));
                        }
                    });
                }),
                switchMap(() => of(this.queryParams))
            );
    }

    paginate(rows: any[]): any[] {
        return rows.slice((this.pageNumber - 1) * this.limit, this.pageNumber * this.limit);
    }

    initSearchObservableWithServerPaging<T>(
        service: (httpParams: HttpParams) => Observable<Result<T>>,
        searchFilter: FormControl | FormGroup | null): Observable<T[]> {
        this.criteria.clear();
        this.paginate = (rows => rows);
        const pageChanging$ = this.onPageChanged$
            .pipe(
                distinctUntilChanged(),
                startWith(this.pageNumber)
            );
        const filter$ = searchFilter.valueChanges
            .pipe(
                startWith(searchFilter.value),
                distinctUntilChanged(),
                debounceTime(250),
                tap((q) => {
                    if (searchFilter instanceof FormGroup) {
                        this.setFormGroupToCriteria(searchFilter);
                    } else {
                        q ? this.criteria.set('q', q) : this.criteria.delete('q');
                    }
                    this.pageNumber = 1;
                })
            );
        this.loading$.next(true);
        return combineLatest([pageChanging$, filter$, this.reset$])
            .pipe(
                tap(() => this.loading$.next(true)),
                switchMap(_ => {
                    return service(this.httpParams);
                }),
                tap(data => this.totalElements = data.meta.count),
                map(r => r.data),
                tap((r) => {
                    if ((r.length / this.limit) > this.pageNumber) {
                        this.pageNumber = 1;
                    }
                    this.loading$.next(false);
                    this.updateUrl();
                })
            );
    }

    // tslint:disable-next-line:max-line-length
    initSearchObservableWithoutPaging<T>(
        service: (httpParams: HttpParams) => Observable<Result<T>>,
        searchControl: FormControl | FormGroup | null,
        filteredFields: string[]): Observable<T[]> {
        this.pageNumber = 1;
        this.limit = 9999;
        this.criteria.clear();
        const datas$ = this.reset$
            .pipe(
                switchMap(() => service(this.httpParams)),
                tap(r => this.totalElements = r.meta.count),
                map(r => r.data)
            );
        const filter$ = searchControl.valueChanges
            .pipe(
                startWith(''),
                debounceTime(250)
            );

        return combineLatest([datas$, filter$])
            .pipe(
                map(([data, filter]) => this.filterFn(data, filter, filteredFields)),
                tap(() => this.updateUrl())
            );
    }

    filterFn(data: any, filter: string | object, fields: string[]): any {
        if ((!filter) || (typeof filter === 'string' && filter.trim().length === 0)) {
            return data;
        }
        if (typeof filter === 'string') {
            return this.filterByString(data, filter.toLocaleLowerCase(), fields);
        } else {
            return this.filterByObject(data, {...filter}, fields);
        }
    }

    filterByString(data: any[], filter: string, fields: string[]): any[] {
        return data.filter(client => fields.some(field => {
            const prop = getDescendantProp(field, client);
            return (prop && prop.toLocaleLowerCase().includes(filter));
        }));
    }

    filterByObject(data: any[], filter: any, fields: string[]): any[] {
        let result: any[] = [...data];
        if (filter.q) {
            result = this.filterByString(data, filter.q, fields);
        }
        delete filter.q;
        // tslint:disable-next-line:forin
        for (const key in filter) {
            if (filter[key] !== null && filter[key] !== undefined) {
                result = result.filter(d => {
                    const prop = getDescendantProp(key, d);
                    return (prop && prop.toLocaleLowerCase().includes(filter[key]));
                });
            }
        }
        return result;
    }

    reloadData(): void {
        this.reset$.next(true);
    }

    gotoPage(pageNumber: number): void {
        this.pageNumber = pageNumber;
        this.onPageChanged$.emit(this.pageNumber);
    }

    private setFormGroupToCriteria(formGroup: FormGroup): void {
        const values: Record<string, any> = formGroup.value;
        // tslint:disable-next-line:forin
        for (const value in values) {
            const newVar = values[value];
            newVar !== undefined && newVar !== null ? this.criteria.set(value, newVar) : this.criteria.delete(value);
        }
    }
}
