import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CreatePasswordForm } from '@mcv/core';
import { AuthService } from '../../services/auth.service';
import { McvnotifierService } from '@mcv/ui';

@Component({
    selector: 'mcv-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    passForm: FormGroup = this.fb.group({
        id: [],
        key: [],
        password: []
    });
    saving = false;

    constructor(private authService: AuthService,
                private fb: FormBuilder,
                private router: Router,
                private notifierService: McvnotifierService,
                private activatedRoute: ActivatedRoute) {
        const { id, key } = this.activatedRoute.snapshot.queryParams;
        this.passForm = CreatePasswordForm(this.fb);
        this.passForm.addControl('key', this.fb.control({}));
        this.passForm.patchValue({ public_id: id, key });
    }

    async ngOnInit(): Promise<void> {
        try {
            const result = await this.authService.checkPasswordCode(this.passForm.value).toPromise();

            if (result.success === false) {
                this.notifierService.error('Votre lien de réinitialisation de mot de passe à expiré.');
                this.passForm.disable();
                await this.redirectToToResendEmail();
            }
        } catch (e) {
            await this.handleError(e);
        }
    }

    async resetPassword(password: string): Promise<void> {
        this.passForm.patchValue({ password });
        try {
            this.saving = true;
            await this.authService.confirmPasswordReset(this.passForm.value).toPromise();
            this.notifierService.success('Mot de passe modifié avec succès, vous pouvez utiliser votre nouveau mot de passe pour vous connecter');
            await this.router.navigate(['/auth/login']);
        } catch (e) {
            await this.handleError(e);
        } finally {
            this.saving = false;
        }
    }

    private async handleError(e) {
        this.passForm.disable();

        switch (e.status) {
        case 400:
            await this.redirectToInvalidLink();
            break;
        case 403:
            await this.redirectToToResendEmail();
            break;
        default:
            this.notifierService.error(e);
        }
    }

    private async redirectToInvalidLink() {
        await this.router.navigate(['auth', 'reset-link-invalid']);
    }

    private async redirectToToResendEmail() {
        await this.router.navigate(['auth', 'reset-link-expired'], {
            queryParams: {
                id: this.passForm.get('public_id').value,
                key: this.passForm.get('key').value
            }
        });
    }
}
