import {Component, Inject, OnInit} from '@angular/core';
import {Catalogue, CatalogueItems} from '@cseemploye/app/shared/pages/catalog/catalogue';
import {BASEURL} from '@mcv/config';

@Component({
    selector: 'mcv-catalog',
    templateUrl: './catalog.component.html',
    styleUrls: ['./catalog.component.scss']
})
export class CatalogComponent implements OnInit {
    catalogueItems: Catalogue[] = CatalogueItems;

    constructor(@Inject(BASEURL) public baseUrl: string) {
    }

    ngOnInit(): void {
    }

}
