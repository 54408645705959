import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {BASEURL} from '@mcv/config';
import {BaseRepository, Rttransaction} from '@mcv/core';

@Injectable({
    providedIn: 'root'
})
export class RttransactionService extends BaseRepository<Rttransaction> {
    model = 'rttransaction';

    constructor(protected httpClient: HttpClient, @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl);
    }
}
