<ng-container *ngIf="balance$ | async as balance; else loading">
    <ng-container *ngIf="transactions$ | async as transactions; else loading">
        <ng-container [ngSwitch]="viewMode">
            <ng-container *ngSwitchCase="'list'">
                <div class="container-fluid text-center bg-white pt-4">
                    <div class="text-uppercase text-secondary">Votre solde</div>
                    <h1 class="mb-0" data-private>{{balance.currentBalance | money:balance.currency}}</h1>
                    <ng-container *ngIf="balance.authorizations > 0">
                        <div class="text-secondary">Opérations à venir
                            - {{balance.authorizations | currency:'EUR':'symbol'}}</div>
                        <div class="text-secondary">(Inclus les pré-autorisations)</div>
                    </ng-container>
                </div>
                <ng-container *ngIf="currentUser$ | async as currentUser">
                    <div *ngIf="currentUser.topup_cb_enabled; else spacer" class="p-4 text-center bg-white"
                         style="background: linear-gradient(to bottom, white 50%, #F8F8F8 50%);">
                        <a [routerLink]="['/client/compte/topup']" class="btn btn-primary rounded-pill">
                            <i aria-hidden="true" class="far fa-plus fa-fw"></i>
                            <ng-container>Ajouter de l'argent</ng-container>
                        </a>
                    </div>
                    <ng-template #spacer>
                        <div class="p-4 text-center bg-warning text-white">
                            Votre compte n'a pas encore été rechargé par votre CSE
                        </div>
                    </ng-template>
                </ng-container>
                <mcv-transaction-list (changePage)="changePage($event)"
                                      *ngIf="transactions.length > 0; else noTransact"
                                      [count]="pagingService.totalElements"
                                      [itemsPerPage]="pagingService.limit"
                                      [transactions]="transactions"></mcv-transaction-list>
                <ng-template #noTransact>
                    <div class="p-4">Vous n'avez pas encore d'opérations enregistrées</div>
                </ng-template>

            </ng-container>
            <mcv-transac-detail (changePage)="changePage($event)"
                                *ngSwitchCase="'detail'"
                                [itemsPerPage]="pagingService.limit"
                                [transactions]="transactions"></mcv-transac-detail>
        </ng-container>
    </ng-container>
</ng-container>
<ng-template #loading>
    <div class="d-flex align-items-center justify-content-center" style="height: 100vh">
        <mat-progress-spinner mode="indeterminate">
        </mat-progress-spinner>
    </div>
</ng-template>
