import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CarouselModule} from 'ngx-bootstrap/carousel';
import {Step1Component} from './ui/step1/step1.component';
import {Step2Component} from './ui/step2/step2.component';
import {Step3Component} from './ui/step3/step3.component';
import {WelcomeComponent} from './pages/welcome/welcome.component';
import {Step4Component} from './ui/step4/step4.component';
import {Step5Component} from './ui/step5/step5.component';
import {Step6Component} from './ui/step6/step6.component';


@NgModule({
    declarations: [
        Step1Component,
        Step2Component,
        Step3Component,
        WelcomeComponent,
        Step4Component,
        Step5Component,
        Step6Component
    ],
    imports: [
        CommonModule,
        CarouselModule
    ]
})
export class WelcomeModule {
}
