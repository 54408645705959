import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '@cseemploye/app/auth/services/auth.service';
import {PagingService} from '@cseemploye/app/shared/services/paging.service';
import {TransactionsService} from '@cseemploye/app/shared/services/transactions.service';
import {environment} from '@cseemploye/environments/environment';
import {Balance, CseEmploye, Transaction} from '@mcv/core';
import {BalancesService} from '@mcv/coreservices';
import {McvnotifierService} from '@mcv/ui';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Component({
    selector: 'mcv-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss'],
    providers: [
        PagingService
    ]
})

export class AccountComponent implements OnInit {
    balance$: Observable<Balance>;
    transactions$: Observable<Transaction[]>;
    paging$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
    environnement = environment;
    limit = 10;
    searchFilter: FormGroup = this.fb.group({});
    viewMode = 'list';
    currentUser$: Observable<CseEmploye>;

    constructor(private balanceService: BalancesService,
                public pagingService: PagingService,
                private notifierService: McvnotifierService,
                private fb: FormBuilder,
                private authService: AuthService,
                private activatedRoute: ActivatedRoute,
                private transactionService: TransactionsService,
                private router: Router) {
        this.currentUser$ = this.authService.user$;
        this.balance$ = this.balanceService
            .view()
            .pipe(
                map(r => r.data),
                catchError((e: any, caught: Observable<Balance>) => {
                    this.notifierService.error(e);
                    return of({} as Balance);
                })
            );
        // @ts-ignore
        this.transactions$ = this.pagingService
            .initSearchObservableWithServerPaging(
                this.transactionService.getAllTransactions.bind(this.transactionService),
                this.searchFilter
            )
            .pipe(
                catchError((e: any, caught: any) => {
                    this.notifierService.error(e);
                    return of([]);
                })
            );
    }

    ngOnInit(): void {
        // redirecting immediately to new transaction log
        this.router.navigate(['client', 'transaction', 'index']);

        this.viewMode = this.activatedRoute.snapshot.data.view || this.viewMode;
    }

    isDev(): boolean {
        return this.environnement.client_env === 'local' || this.environnement.client_env === 'dev';
    }

    changePage($event: number): void {
        this.pagingService.gotoPage($event);
    }
}
