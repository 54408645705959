<div class="container-fluid p-0 h-100" style="overflow: auto">

    <div class="d-flex flex-column h-100">

        <div class="d-flex flex-grow-0 mb-2 mt-2 ml-auto mr-auto">
            <div class="text-center text--title__font">
                Les prestations non autorisées
            </div>
        </div>

        <div class="d-flex flex-shrink-1 justify-content-center my-3">
            <div class="subtitle2 text-center">
                En dehors des prestations vacances, sport, culture, <b>macartevacances ne peut par exemple pas être
                utilisée</b> afin de payer :
            </div>
        </div>

        <div class="flex-fill d-flex">
            <div class="d-flex align-items-center">
                <div class="d-flex flex-row mr-auto ml-auto item-width">
                    <img class="mr-3" src="{{baseUrl}}/img/carousel/forbidden.svg">
                    <div class="d-flex flex-column">
                        <div class="text-left item-title">
                            Alimentation
                        </div>
                        <div class="text-left item-description">
                            Restaurants, courses alimentaires, alcool, tabac…
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="hasGiftBudget === false" class="flex-fill d-flex">
            <div class="d-flex align-items-center">
                <div class="d-flex flex-row mr-auto ml-auto item-width">
                    <img class="mr-3" src="{{baseUrl}}/img/carousel/forbidden.svg">
                    <div class="d-flex flex-column">
                        <div class="text-left item-title">
                            Biens matériels
                        </div>
                        <div class="text-left item-description">
                            Consoles de jeux, télévisions, vêtements, jouets…
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex-fill d-flex">
            <div class="d-flex align-items-center">
                <div class="d-flex flex-row mr-auto ml-auto item-width">
                    <img class="mr-3" src="{{baseUrl}}/img/carousel/forbidden.svg">
                    <div class="d-flex flex-column">
                        <div class="text-left item-title">
                            Carburant
                        </div>
                        <div class="text-left item-description">
                            Dépenses en station-service
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex-fill d-flex pb-4">
            <div class="d-flex align-items-center">
                <div class="d-flex flex-row  mr-auto ml-auto item-width">
                    <img class="mr-3" src="{{baseUrl}}/img/carousel/forbidden.svg">
                    <div class="d-flex flex-column">
                        <div class="text-left item-title">
                            Retrait d’argent
                        </div>
                        <div class="text-left item-description">
                            Distributeurs de billets
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
