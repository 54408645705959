import {Component, Input, OnInit} from '@angular/core';
import {CseEmploye} from '@mcv/core';

@Component({
    selector: 'mcv-profile-top',
    templateUrl: './profile-top.component.html',
    styleUrls: ['./profile-top.component.scss']
})
export class ProfileTopComponent implements OnInit {
    @Input() cseEmploye: CseEmploye;

    constructor() {
    }

    ngOnInit(): void {
    }

}
