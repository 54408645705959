<div class="container-fluid p-0 h-100" style="overflow: auto">

    <div class="d-flex flex-column h-100">

        <div class="d-flex flex-grow-0 mt-2 mb-2 ml-auto mr-auto">
            <div class="text-center text--title__font">
                Les prestations autorisées
            </div>
        </div>

        <div class="d-flex flex-fill justify-content-center mt-auto mb-auto">
            <div class="d-flex align-items-center">
                <div class="d-flex flex-row mr-auto ml-auto item-width">
                    <img class="mr-3" src="{{baseUrl}}/img/carousel/hotel_clr.svg">
                    <div class="d-flex flex-column">
                        <div class="text-left item--title__font">
                            Séjours et hébergements
                        </div>
                        <div class="text-left item--body__font">
                            Hôtels, clubs de vacances, campings, colonies, gîtes…
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex-fill d-flex justify-content-center">
            <div class="d-flex align-items-center">
                <div class="d-flex flex-row mr-auto ml-auto item-width">
                    <img class="mr-3" src="{{baseUrl}}/img/carousel/train_clr.svg">
                    <div class="d-flex flex-column">
                        <div class="text-left item--title__font">
                            Voyages et transports
                        </div>
                        <div class="text-left item--body__font">
                            Agences de voyage, trains, avions, location, péages…
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex-fill d-flex justify-content-center">
            <div class="d-flex align-items-center">
                <div class="d-flex flex-row mr-auto ml-auto item-width">
                    <img class="mr-3" src="{{baseUrl}}/img/carousel/canoe_clr.svg">
                    <div class="d-flex flex-column">
                        <div class="text-left item--title__font">
                            Loisirs sportifs et récréatifs
                        </div>
                        <div class="text-left item--body__font">
                            Salles de sport, bases de loisirs, clubs de sport, piscine, bowling…
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div [ngClass]="{'pb-4': hasGiftBudget === false}" class="flex-fill d-flex justify-content-center">
            <div class="d-flex align-items-center">
                <div class="d-flex flex-row  mr-auto ml-auto item-width">
                    <img class="mr-3" src="{{baseUrl}}/img/carousel/capitol_clr.svg">
                    <div class="d-flex flex-column">
                        <div class="text-left item--title__font">
                            Culture et découverte
                        </div>
                        <div class="text-left item--body__font">
                            Spectacles, cinémas, théâtres, parcs d’attraction, musées…
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="hasGiftBudget" class="flex-fill d-flex justify-content-center pb-4">
            <div class="d-flex align-items-center">
                <div class="d-flex flex-row  mr-auto ml-auto item-width">
                    <img class="mr-3" src="{{baseUrl}}/img/carousel/gift.svg" style="width: 47px">
                    <div class="d-flex flex-column">
                        <div class="text-left item--title__font">
                            Biens matériels
                        </div>
                        <div class="text-left item--body__font">
                            Biens festifs, culturels ou sportifs, les jouets, la décoration, la mode, les bijoux, le
                            bricolage etc…
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
