<form (ngSubmit)="saveForm()" [formGroup]="passForm">
    <div class="card border-light rounded-1 mt-4">
        <div class="card-body">
            <h5 class="card-title text-secondary">
                {{updating ? 'Mise à jour du mot de passe' : 'Création du mot de passe'}}
            </h5>
            <div *ngIf="askOldPassword" class="form-group">
                <label class="control-label" for="oldpassword">
                    Ancien mot de passe
                </label>
                <mcv-password-input autocomplete="oldpassword"
                                    data-cy="oldpassword"
                                    formControlName="oldpassword"
                                    id="oldpassword"
                                    name="oldpassword"></mcv-password-input>
                <val-errors controlName="oldpassword"></val-errors>
            </div>
            <div class="form-group">
                <label class="control-label" for="employe-password">
                    {{updating ? 'Nouveau' : 'Choix du'}} mot de passe
                </label>
                <mcv-password-input autocomplete="new-password"
                                    data-cy="password"
                                    formControlName="password"
                                    id="employe-password"
                                    name="password"></mcv-password-input>
                <val-errors controlName="password"></val-errors>
                <small class="form-text text-muted" id="password-help">
                    Le mot de passe doit inclure au moins un chiffre, une lettre en majuscule, un caractère spécial et
                    avoir une longueur minimum de 8
                    caractères.
                </small>
            </div>

            <div class="form-group">
                <label class="control-label" for="employe-password2">Confirmer le mot de passe</label>
                <mcv-password-input autocomplete="new-password"
                                    data-cy="password2"
                                    formControlName="password2"
                                    id="employe-password2"
                                    name="password2"></mcv-password-input>
                <val-errors controlName="password2"></val-errors>
            </div>
            <mcv-password-usage-rules [passwordConfirmation]="passForm.value.password2"
                                      [password]="passForm.value.password"></mcv-password-usage-rules>
            <div *ngIf="passForm.errors?.notSame && passForm.get('password2').dirty"
                 class="text-danger"
                 data-cy="pwdnotsameerror">
                Les mots de passe ne correspondent pas
            </div>
            <div class="form-row mt-3 d-flex justify-content-center">
                <div class="col-xs-12 col-lg-6">
                    <button [disabled]="!passForm.valid || saving"
                            [promiseBtn]="saving"
                            class="btn btn-primary rounded-pill btn-block"
                            data-cy="submitpassword"
                            type="submit">
                        {{submitLabel}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>
