export interface Catalogue {
    title: string;
    logo: string;
    target: Array<{
        name: string;
        desc: string;
        merchangeImage: string;
    }>;
}

export const CatalogueItems: Catalogue[] = [
    {
        title: 'Séjours & Hébergement',
        logo: '/img/catalogue/headerSection1.png',
        target: [
            {
                name: 'hôtels et clubs',
                desc: 'Vous partez en weekend ou sur un séjour longue durée ? Réservez votre hébergement chez vos marchands préférés !',
                merchangeImage: '/img/catalogue/section1.png'
            },
            {
                name: 'agences de voyage',
                desc: 'Vous souhaitez qu’une agence de voyage s’occupe de tout ? Pas d’inquiétudes, macartevacances est compatible !',
                merchangeImage: '/img/catalogue/section2.png'
            },
            {
                name: 'campings',
                desc: 'Vous partez à la mer ou à la campagne en réservant votre séjour en camping : ambiance familiale, farniente au soleil et activités pour tous !',
                merchangeImage: '/img/catalogue/section3.png'
            }
        ]
    },
    {
        title: 'Voyages & Transports',
        logo: '/img/catalogue/headerSection4.png',
        target: [
            {
                name: 'compagnies aériennes',
                desc: 'Envie de s’évader ? Réservez un vol chez votre compagnie aérienne préférée !',
                merchangeImage: '/img/catalogue/section4.png'
            },
            {
                name: 'opérateurs ferroviaires',
                desc: 'Réservez votre billet de train et voyagez  en France et à l’international !',
                merchangeImage: '/img/catalogue/section5.png'
            },
            {
                name: 'compagnies de bus & autocars',
                desc: 'Voyagez en tout lieu grâce à un réseau de lignes d’autocar à longue distance !',
                merchangeImage: '/img/catalogue/section6.png'
            },
            {
                name: 'acteurs du nautisme',
                desc: 'Effectuez une croisière, louez un bateau ou traversez la mer à bord d’un voilier !',
                merchangeImage: '/img/catalogue/section7.png'
            },
            {
                name: 'agences de location de véhicules',
                desc: 'Voitures ou camping-cars, louez le modèle qui vous convient et voyagez au volant d’un véhicule qui saura seconder toutes vos envies !',
                merchangeImage: '/img/catalogue/section8.png'
            },
            {
                name: 'services de covoiturages & péage',
                desc: 'Partagez les frais d’un trajet en voiture ou en bus en optimisant le remplissage du véhicule !',
                merchangeImage: '/img/catalogue/section9.png'
            }
        ]
    },
    {
        title: 'Culture & Découverte',
        logo: '/img/catalogue/headerSection10.png',
        target: [
            {
                name: 'services de streaming et cinémas',
                desc: 'Envie de passer un bon moment, de vivre des sensations fortes ou encore de cultiver votre savoir ? C’est parti !',
                merchangeImage: '/img/catalogue/section10.png'
            },
            {
                name: 'parcs à thème',
                desc: 'Vous aimez les sensations fortes, vous amuser en famille ou encore rire entre amis ?',
                merchangeImage: '/img/catalogue/section11.png'
            },
            {
                name: 'parcs à thème',
                desc: 'Vous aimez les sensations fortes, vous amuser en famille ou encore rire entre amis ?',
                merchangeImage: '/img/catalogue/section12.png'
            },
            {
                name: 'évènements',
                desc: 'Concert, Festival, Spectacle, évènement sportif ou encore théâtre et humour :réservez les meilleures places !',
                merchangeImage: '/img/catalogue/section13.png'
            },
            {
                name: 'musées et lieux de découverte',
                desc: 'Découvrez les plus beaux monuments, musées de France et d’ailleurs',
                merchangeImage: '/img/catalogue/section14.png'
            }
        ]
    },
    {
        title: 'Loisirs sportifs & récréatifs',
        logo: '/img/catalogue/headerSection15.png',
        target: [
            {
                name: 'loisirs et services sportifs',
                desc: 'Vous êtes à la recherche d\'une activité insolite, sportive ou de détente ?',
                merchangeImage: '/img/catalogue/section15.png'
            }
        ]
    }
];
