<div class="container-fluid p-0 h-100" style="overflow: auto">
    <div class="d-flex flex-column h-100 justify-content-between">
        <div class="text-center text--title__font">
            Inutile de demander,
            <br>
            elle passe partout !
        </div>

        <div class="flex-grow-1 text-center text--line__font align-items-center d-flex mt-5">
            Il n’est pas nécessaire de demander aux commerçants s’ils acceptent macartevacances
        </div>

        <div class="flex-grow-1 text-center text--line__font align-items-center d-flex">
            <b>La réponse est oui, car c’est une Mastercard</b>
        </div>

        <div class="flex-grow-1 text-center text--line__font align-items-center d-flex">
            Aucune configuration du terminal de paiement n’est nécessaire
        </div>

        <div class="flex-fill mt-5">
            <div class="d-flex flex-column">
                <img class="img-fluid align-self-center"
                     src="{{baseUrl}}/img/carousel/carousel_exchange.svg" style="width: 90%"/>
                <img class="img-fluid align-self-center"
                     src="{{baseUrl}}/img/carousel/carousel_visa.svg" style="width: 40%"/>
            </div>
        </div>
    </div>
</div>
