<div class="dialog-container d-flex flex-column p-4" style="position: relative">
    <div class="flex-grow-1 h-100 mt-4">
        <ng-container [ngSwitch]="step">
            <mcv-step1 (nextStep)="step = 2" *ngSwitchCase="1"></mcv-step1>
            <mcv-step2 (nextStep)="step = 3" *ngSwitchCase="2"></mcv-step2>
            <mcv-step3 (nextStep)="step = 4" *ngSwitchCase="3"></mcv-step3>
            <mcv-step4 (nextStep)="step = 5" *ngSwitchCase="4"></mcv-step4>
            <mcv-step5 (nextStep)="step = 6" *ngSwitchCase="5"></mcv-step5>
            <mcv-step6 (nextStep)="close()" *ngSwitchCase="6"></mcv-step6>
        </ng-container>
    </div>
    <div class="flex-shrink-0 justify-content-center align-items-center d-flex flex-column">
        <button data-cy="carousel-next" (click)="nextStep()"
                class="btn btn-primary btn-block mt-auto pb-2 pl-2 pr-2 btn--next__size">
            {{step < 6 ? 'Continuer' : 'J\'ai compris'}}
        </button>
        <ol class="mcv-carousel-indicators mt-2 mb-0">
            <li (click)="step = 1" [ngClass]="step === 1 ? 'active':''"></li>
            <li (click)="step = 2" [ngClass]="step === 2 ? 'active':''"></li>
            <li (click)="step = 3" [ngClass]="step === 3 ? 'active':''"></li>
            <li (click)="step = 4" [ngClass]="step === 4 ? 'active':''"></li>
            <li (click)="step = 5" [ngClass]="step === 5 ? 'active':''"></li>
            <li (click)="step = 6" [ngClass]="step === 6 ? 'active':''"></li>
        </ol>
    </div>
</div>
