<div class="card border-light rounded-1 mt-4">
    <div class="card-body">
        <h5 class="card-title text-secondary">Préférences de communication</h5>

        <div class="row">
            <div class="col-md-3">
                <label class="mt-2" for="cse-employe-email">Adresse email professionnelle</label>
            </div>
            <div class="col-md-6">
                <input class="form-control-plaintext"
                       disabled
                       id="cse-employe-email"
                       readonly
                       type="text"
                       value="{{cseEmploye.email}}">
            </div>
        </div>
        <form (ngSubmit)="checkPassword(confirmPasswordModale, 'mail')" [formGroup]="mailForm">
            <div class="row">
                <div class="col-md-3">
                    <label class="mt-2 control-label" for="employe-email-alt">
                        Adresse email personnelle
                    </label>
                </div>
                <div class="col-md-6">
                    <input class="form-control"
                           disabled
                           formControlName="email_alt"
                           id="employe-email-alt"
                           placeholder="compte@email.fr"
                           type="email">
                </div>
            </div>
        </form>
        <form (ngSubmit)="checkPassword(confirmPasswordModale, 'mobile')" [formGroup]="mobileForm">
            <div class="row mt-2">
                <div class="col-md-3">
                    <label class="mt-2 control-label">Numéro de mobile</label>
                </div>
                <div class="col-md-6">
                    <mcv-phone-input [disabled]="true" formControlName="mobile"></mcv-phone-input>
                </div>
            </div>
        </form>
    </div>
</div>

<ng-template #confirmPasswordModale>
    <div class="modal-header">
        <h5 class="modal-title">Confirmer votre mot de passe</h5>
        <button (click)="hide()" aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form (ngSubmit)="confirmPassword(confirmCodeModale)" [formGroup]="confirmPasswordForm">
            <p>
                Merci de confirmer votre mot de passe pour modifier vos informations personnelles.
            </p>
            <div class="form-group">
                <label class="control-label" for="password">Mot de passe :</label>
                <mcv-password-input autocomplete="password"
                                    data-cy="password"
                                    formControlName="password"
                                    id="password"
                                    name="password"></mcv-password-input>
            </div>

            <div class="form-group">
                <button class="btn btn-primary btn-block" type="submit">Valider le mot de passe</button>
            </div>

            <div class="form-group">
                <button (click)="hide()" class="btn btn-outline-secondary btn-block" type=button>
                    Annuler
                </button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #confirmCodeModale>
    <div class="modal-header">
        <h5 class="modal-title">{{title}}</h5>
        <button (click)="modalRef.hide()" aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form (ngSubmit)="confirmCode()" [formGroup]="confirmCodeForm">
            <p>
                {{message}}
            </p>
            <div class="form-group">
                <label class="control-label" for="code-email">Code :</label>
                <input class="form-control" formControlName="code" id="code-email" name="code" type="text">
            </div>

            <div class="form-group">
                <button class="btn btn-primary btn-block" type="submit">Valider le code</button>
            </div>

            <div class="form-group">
                <button (click)="modalRef.hide()" class="btn btn-outline-secondary btn-block" type=button>
                    Annuler
                </button>
            </div>
        </form>
    </div>
</ng-template>
