import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BASEURL } from '@mcv/config';
import { BaseRepository, CseEmployeKyc, SingleResult } from '@mcv/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CseEmployeKycService extends BaseRepository<CseEmployeKyc> {
    model = 'cse-employe/cse-employe-kyc';

    constructor(protected httpClient: HttpClient, @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl);
    }

    getKycState(): Observable<SingleResult<CseEmployeKyc>> {
        return this.httpClient.get<any>(`${this.baseUrl}/${this.model}/index`);
    }

    generateKycUrl(location: string): Observable<SingleResult<{ url: string }>> {
        return this.httpClient.post<any>(`${this.baseUrl}/${this.model}/getKycLivenessURL`, { 'callback': location });
    }

    sendKycReview(trzUserId: number): Observable<SingleResult<any>> {
        return this.httpClient.post<SingleResult<any>>(`${this.baseUrl}/${this.model}/sendKycReview/${trzUserId}`, {});
    }

}
