import {CommonModule, registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {CatalogComponent} from '@cseemploye/app/shared/pages/catalog/catalog.component';
import {WelcomeModule} from '@cseemploye/app/welcome/welcome.module';
import {UiModule} from '@mcv/ui';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {CountdownModule} from 'ngx-countdown';
import {NgxFileHelpersModule} from 'ngx-file-helpers';
import {ValdemortModule} from 'ngx-valdemort';
import {SharedModule} from '../shared/shared.module';

import {ClientRoutingModule} from './client-routing.module';
import {IndexComponent} from './pages/index/index.component';
import {NavbarComponent} from './ui/navbar/navbar.component';
import {OutsiderWarningComponent} from './ui/outsider-warning/outsider-warning.component';

registerLocaleData(localeFr);

@NgModule({
    declarations: [
        IndexComponent,
        NavbarComponent,
        CatalogComponent,
        OutsiderWarningComponent
    ],
    imports: [
        FormsModule,
        CommonModule,
        CountdownModule,
        ClientRoutingModule,
        SharedModule,
        NgxFileHelpersModule,
        MatInputModule,
        MatIconModule,
        ValdemortModule,
        UiModule,
        PopoverModule,
        WelcomeModule,
        MatDialogModule,
        AngularSvgIconModule
    ],
    exports: [],
    providers: [
        {provide: LOCALE_ID, useValue: 'fr-FR'}
    ]
})
export class ClientModule {
}
