import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CseEmployeJustificatif, JUSTIFICATIFSTATE, Transaction} from '@mcv/core';

@Component({
    selector: 'mcv-transaction-justificatif',
    templateUrl: './transaction-justificatif.component.html',
    styleUrls: ['./transaction-justificatif.component.scss']
})
export class TransactionJustificatifComponent implements OnInit {
    @Output() sent: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() transaction: Transaction;
    showUpload = false;
    showInProgress = false;
    showInvalid = false;
    showValid = false;

    constructor() {
    }

    ngOnInit(): void {
        this.computeState();
    }

    computeState() {
        this.showUpload = !this.transaction.cse_employe_justificatif || this.transaction.cse_employe_justificatif.state === JUSTIFICATIFSTATE.ko;
        this.showInvalid = this.transaction.cse_employe_justificatif?.state === JUSTIFICATIFSTATE.ko;
        this.showInProgress = this.transaction.cse_employe_justificatif?.state === JUSTIFICATIFSTATE.new;
        this.showValid = this.transaction.cse_employe_justificatif?.state === JUSTIFICATIFSTATE.ok;
    }

    setJustificatif(cse_employe_justificatif: CseEmployeJustificatif) {
        this.transaction.cse_employe_justificatif = cse_employe_justificatif;
        this.computeState();
    }
}
