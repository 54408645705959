<div [formGroup]="aboForm" class="card border-light rounded-1 mt-4">
    <div class="card-body">
        <h5 class="card-title text-secondary">Abonnements</h5>
        <ng-container *ngIf="cseEmploye.email_alt">
            <div class="row">
                <div class="col-md-10 offset-md-2">
                    <mcv-toggle formControlName="abo_use_email_alt">
                        Je préfere être contacté via mon email personnel
                    </mcv-toggle>
                </div>
            </div>
        </ng-container>
        <div class="row my-2">
            <div class="col-md-10 offset-md-2">
                <mcv-toggle formControlName="abo_email">
                    J’accepte de recevoir les actualités macartevacances (informations produits & offres
                    promotionnelles)
                </mcv-toggle>
            </div>
        </div>
        <div class="row">
            <div class="col-md-10 offset-md-2">
                <mcv-toggle formControlName="abo_perso">
                    J’accepte de recevoir des contenus personnalisés** en fonction de mes interactions avec
                    macartevacances (parcours d’achat, navigation…)
                </mcv-toggle>
            </div>
        </div>
        <div class="row">
            <div class="col-md-10 offset-md-2">
                <mcv-toggle formControlName="abo_mobile">
                    Je ne souhaite pas recevoir de SMS (hors code d'authentification)
                </mcv-toggle>
            </div>
        </div>
    </div>
</div>
