import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CseEmployeJustificatifService} from '@cseemploye/app/shared/services/cse-employe-justificatif.service';
import {convertToFormData, CseEmployeJustificatif} from '@mcv/core';
import {McvnotifierService} from '@mcv/ui';
import {ReadFile} from 'ngx-file-helpers';
import {map} from 'rxjs/operators';

const MO = 1000 * 1000;

@Component({
    selector: 'mcv-justificatif-button-upload',
    templateUrl: './justificatif-button-upload.component.html',
    styleUrls: ['./justificatif-button-upload.component.scss']
})
export class JustificatifButtonUploadComponent implements OnInit {
    @Input() publicId: string;
    @Input() redirect = true;
    @Input() publicPaymentId: string;
    @Input() cse_employe_public_id: string;
    @Input() buttonText: string;
    @Input() classBtn = 'btn btn-sm btn-primary';
    @Input() classIcn = 'far fa-paperclip fa-fw';
    @Output() sent: EventEmitter<CseEmployeJustificatif> = new EventEmitter<CseEmployeJustificatif>();
    uploading = false;
    uploadForm: FormGroup = this.fb.group({
        public_id: [],
        cse_employe_public_id: [],
        publicPaymentId: [],
        redirect: [],
        file: [null, [Validators.required]]
    });
    fakeForm: FormGroup = this.fb.group({
        file: [null, [Validators.required]]
    });

    constructor(private fb: FormBuilder,
                private cseEmployeJustificatifService: CseEmployeJustificatifService,
                private notifierService: McvnotifierService) {
    }

    ngOnInit(): void {
        this.uploadForm.patchValue({
            publicPaymentId: this.publicPaymentId,
            cse_employe_public_id: this.cse_employe_public_id,
            redirect: this.redirect
        });
        if (this.publicId) {
            this.uploadForm.patchValue({
                public_id: this.publicId
            });
        }
    }

    async uploadJustificatif(): Promise<void> {
        try {
            this.uploading = true;
            this.cseEmployeJustificatifService.autoRedirect = this.redirect;
            const formData: FormData = convertToFormData(this.uploadForm.value);
            const cse_employe_justificatif = await this.cseEmployeJustificatifService
                .add(formData)
                .pipe(map(r => r.data))
                .toPromise();
            this.notifierService.success('Justificatif envoyé !');
            this.sent.emit(cse_employe_justificatif);
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.uploading = false;
            this.cseEmployeJustificatifService.autoRedirect = true;
        }
    }

    pickFile($event: ReadFile): void {
        if ($event.size > 60 * MO) {
            this.notifierService.error('La taille du fichier ne doit pas dépasser 60 Mo');
            return;
        }
        this.uploadForm.patchValue({file: $event.underlyingFile});
        this.uploadJustificatif();
    }

    saveForm() {
        this.uploadForm.patchValue({file: this.fakeForm.get('file').value});
        this.uploadJustificatif();
    }
}
