<ng-container *ngFor="let monthtransaction of (transactions | groupByMonthYear | keyvalue:sort)">
    <div class="bg-back pb-4">
        <div class="container">
            <div class="card border-light rounded-1 bg-white">
                <div class="card-body">
                    <h3 class="card-title">
                        {{monthtransaction.key | monthYearName | titlecase}}
                        <div class="float-right">
                            <a [routerLink]="['/client/detail']"
                               class="btn btn-sm btn-outline-primary rounded-pill border-0 bg-primary-light">
                                Voir détail
                            </a>
                        </div>
                    </h3>
                </div>
                <mcv-transaction-detail-list *ngFor="let transaction of monthtransaction.value"
                                             [transaction]="transaction">
                </mcv-transaction-detail-list>
            </div>
        </div>
    </div>
</ng-container>
<div *ngIf="count > itemsPerPage" class="container pt-4">
    <nav class="d-flex justify-content-center">
        <pagination (pageChanged)="changePage.emit($event.page)"
                    [itemsPerPage]="itemsPerPage"
                    [totalItems]="count"
                    firstText="Premier"
                    lastText="Dernier"
                    nextText="Suivant"
                    previousText="Précédent"></pagination>
    </nav>
</div>
