import { Component, Inject, OnInit } from '@angular/core';
import { BASEURL } from '@mcv/config';
import { AuthService } from '@cseemploye/app/auth/services/auth.service';
import { PromotionService } from '@cseemploye/service/promotion.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
    selector: 'mcv-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    constructor(
        @Inject(BASEURL) public baseUrl: string,
        public authService: AuthService,
        private promotion: PromotionService,
        private router: Router,
        private location: Location
    ) {}

    ngOnInit(): void {
    }


    promotionRedirect() {
        this.promotion.redirectInfos().then(r => {
            if (r['data']?.authkey && r['data']?.subdomain) {
                this.promotion.redirect(r['data'].authkey, r['data'].subdomain).then(r2 => {
                    window.open(r2['url'], '_blank');
                });
            }
        }).catch(e => {
            console.log(e);
        });
    }
}
