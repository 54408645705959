import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'sumof'
})
export class SumofPipe implements PipeTransform {

    transform(value: any[], ...args: string[]): unknown {
        if (!args || args.length === 0) {
            return '';
        }
        const operator = args[1] === 'negative' ? this.ltz : this.gtz;
        return value.reduce((acc, curr) => {
            if (operator(curr[args[0]])) {
                acc += curr[args[0]];
                return acc;
            }
            return acc;
        }, 0);
    }

    private gtz(value: number): boolean {
        return value > 0;
    }

    private ltz(value: number): boolean {
        return value <= 0;
    }
}
