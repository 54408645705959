import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { ConnectionService } from '@mcv/coreservices';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { environment } from '@cseemploye/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    disconnected: boolean;

    constructor(private router: Router,
                private connectionService: ConnectionService,
                private authService: AuthService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.connectionService.currentState.hasNetworkConnection) {
            return throwError(new HttpResponse({
                status: 500,
                body: {
                    success: false,
                    errors: [
                        { code: 500, reason: 'Vous n\'êtes pas connecté à Internet' }
                    ]
                }
            }));
        }


        if (request.url.startsWith(environment.promotionBaseUrl)) {
            return next.handle(request);
        }

        const idToken = this.authService.getToken();
        let localRequest: HttpRequest<any> = request;
        if (idToken) {
            localRequest = request.clone({
                headers: request.headers.set('Authorization', 'Bearer ' + idToken)
            });
        }
        return next.handle(localRequest);
    }
}
