import {Inject, Injectable} from '@angular/core';
import {BaseRepository, SingleResult, VirementBeneficiary, VirementOrder} from '@mcv/core';
import {HttpClient} from '@angular/common/http';
import {BASEURL} from '@mcv/config';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class VirementBeneficiaryService extends BaseRepository<VirementBeneficiary> {
    model = 'virement-beneficiary';

    constructor(protected httpClient: HttpClient, @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl);
    }

    createOrUpdate(formData: any): Observable<SingleResult<VirementBeneficiary>> {
        return this.httpClient.put<SingleResult<VirementBeneficiary>>(`${this.baseUrl}/${this.model}/createOrUpdate`, formData);
    }

    doVirement(formData: any): Observable<SingleResult<VirementOrder>> {
        return this.httpClient.post<SingleResult<any>>(`${this.baseUrl}/${this.model}/doVirement`, formData);
    }
}
