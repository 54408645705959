import {Component, Inject, Input, OnInit} from '@angular/core';
import {AuthService} from '@cseemploye/app/auth/services/auth.service';
import {BASEURL} from '@mcv/config';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CseEmployeReclamationService, CseEmployeService} from '@mcv/coreservices';
import {McvnotifierService} from '@mcv/ui';
import {ActivationService} from '@cseemploye/app/activation/services/activation.service';
import {FeatureFlagsService} from '@cseemploye/service/feature-flags.service';
import {Observable, of} from 'rxjs';
import {CseEmploye, Justificatif, SingleResult} from '@mcv/core';
import {catchError, map, tap} from 'rxjs/operators';


@Component({
    selector: 'mcv-new-reclamation',
    templateUrl: './new-reclamation.component.html',
    styleUrls: ['./new-reclamation.component.scss']
})
export class NewReclamationComponent implements OnInit {

    justificatif$: Observable<SingleResult<Justificatif>>;
    @Input() publicId?: string = null;
    loading = true;
    refundRequestShow = false;
    cseEmploye: CseEmploye;
    reclamForm: FormGroup = this.fb.group({
        cse_employe_public_id: [],
        trz_cardtransactionId: [],
        trz_payment_public_id: [],
        marchand: [null, [Validators.required]],
        reclamation: [null, [Validators.required]]
    });
    sending: boolean;
    refundEnabled = false;

    constructor(@Inject(BASEURL) public baseUrl: string,
                private router: Router,
                private fb: FormBuilder,
                private cseEmployeReclamationService: CseEmployeReclamationService,
                private activatedRoute: ActivatedRoute,
                private notifierService: McvnotifierService,
                private activationService: ActivationService,
                private cseEmployeService: CseEmployeService,
                private featureFlags: FeatureFlagsService,
                private authService: AuthService
    ) {
        this.readId();
    }

    async ngOnInit(): Promise<void> {
        this.refundEnabled = await this.featureFlags.isRefundEnabled();
        if (this.publicId) {
            this.justificatif$ = this.activationService
                .reclamation(this.publicId)
                .pipe(
                    tap(async r => {
                        const data: Justificatif = r.data;
                        if (data.hasReclamation) {
                            this.notifierService.success('Votre réclamation a déjà été envoyée');
                            this.router.navigate(['/activation/aide/reclamation_ok']);
                        } else {
                            this.reclamForm.patchValue({
                                cse_employe_public_id: data.cseEmploye.public_id,
                                firstname: data.cseEmploye.firstname,
                                lastname: data.cseEmploye.lastname,
                                trz_cardtransactionId: data.cardTransaction.cardTransactionId,
                                trz_payment_public_id: data.cardTransaction.trz_payment_public_id
                            });
                            try {
                                this.cseEmploye = await this.cseEmployeService.view(data.cseEmploye.public_id).pipe(map(pdc => pdc.data)).toPromise();
                            } catch (e) {
                                this.reclamForm.reset();
                                this.notifierService.error(e);
                            } finally {
                                this.loading = false;
                            }
                        }
                    }),
                    catchError(e => {
                        this.notifierService.error(e);
                        return of(null);
                    })
                );
        } else {
            this.justificatif$ = this.cseEmployeService.view(this.authService.user.public_id)
                .pipe(
                    tap(r => {
                        this.cseEmploye = r.data;
                        this.reclamForm.patchValue({
                            cse_employe_public_id: this.cseEmploye.public_id,
                            firstname: this.cseEmploye.firstname,
                            lastname: this.cseEmploye.lastname
                        });
                    }),
                    catchError(e => {
                        this.notifierService.error(e);
                        return of(null);
                    })
                );
        }
    }

    async saveForm(): Promise<void> {
        try {
            this.sending = true;
            this.cseEmployeReclamationService.autoRedirect = false;
            await this.cseEmployeReclamationService
                .add(this.reclamForm.value)
                .toPromise();
            this.notifierService.success('Votre réclamation a bien été envoyée');
            this.router.navigate(['/activation/aide/reclamation_ok']);
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.cseEmployeReclamationService.autoRedirect = true;
            this.sending = false;
        }
    }

    toggleRefundRequest(isEnabled: boolean) {
        this.refundRequestShow = isEnabled;
    }

    readId() {
        if (this.activatedRoute.snapshot.queryParamMap.has('id')) {
            this.publicId = this.activatedRoute.snapshot.queryParamMap.get('id');
        } else if (this.activatedRoute.snapshot.paramMap.has('id')) {
            this.publicId = this.activatedRoute.snapshot.paramMap.get('id');
        }
    }
}
