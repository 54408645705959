import {HttpClient, HttpParams} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {BASEURL} from '@mcv/config';
import {Result, Transaction} from '@mcv/core';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TransactionsService {
    model = 'transaction';

    constructor(protected httpClient: HttpClient, @Inject(BASEURL) protected baseUrl: string) {
    }

    getAllTransactions(params: HttpParams): Observable<Result<Transaction>> {
        return this.httpClient.get<Result<Transaction>>(`${this.baseUrl}/${this.model}`, {params});
    }
}
