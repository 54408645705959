<div class="card border-light m-md-5 p-md-4">
    <div class="card-body">
        <form [formGroup]="loginForm">
            <div class="card-title row">
                <div class="col"><h2>Se connecter</h2></div>
            </div>
            <mcv-error-display [error]="error"></mcv-error-display>
            <div class="form-group row">
                <div class="col">
                    <label for="email">E-mail professionnel</label>
                    <input autocapitalization="off"
                           autocorrect="off"
                           class="form-control"
                           data-cy="email"
                           formControlName="email"
                           id="email"
                           name="email"
                           placeholder="Email"
                           type="text">
                </div>
            </div>

            <div class="form-group row">
                <div class="col">
                    <label>Mot de passe</label>
                    <mcv-password-input autocomplete=off
                                        data-cy="password_hash"
                                        formControlName="password_hash"
                                        name="password_hash"
                                        placeholder="Mot de passe"></mcv-password-input>
                </div>
            </div>
            <div class="form-group row">
                <div class="col">
                    <a (click)="openLostPasswordDialog($event)" class="text-primary font-weight-bolder pull-right cursor__pointer" href="#">
                        Mot de passe oublié ?
                    </a>
                </div>
            </div>
            <div class="form-group row">
                <div class="col">
                    <button (click)="login()"
                            [disabled]="!loginForm.valid"
                            [promiseBtn]="isLogin"
                            class="btn btn-primary btn-block mb-2"
                            type="submit">
                        Se connecter
                    </button>
                    <div *ngIf="improveSecurity"
                         class="d-flex justify-content-center">
                        <re-captcha formControlName="recaptchaReactive"></re-captcha>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-md-6">
                    <div class="form-group">
                        <a [routerLink]="['/activation/onboarding/']" class="btn btn-outline-primary btn-block">
                            J'ai reçu ma carte
                        </a>
                    </div>
                </div>
                <div class="col-x12 col-md-6">
                    <div class="form-group">
                        <a [routerLink]="['/activation/aide/catalogue']" class="btn btn-outline-primary btn-block">
                            Où utiliser ma carte ?
                        </a>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
