<form (ngSubmit)="addRefund()" [formGroup]="refundForm">
    <div class="card border-light rounded-1 mt-4">
        <div class="card-body">
            <h5 class="card-title text-secondary">Montant de la dépense<small>*</small></h5>
            <div class="p-2">
                <div class="form-group">
                    <div class="input-group">
                        <input autocomplete="off"
                               class="form-control"
                               formControlName="amount"
                               id="amount"
                               inputmode="decimal"
                               max="1000000"
                               min="1"
                               placeholder="0,00"
                               required
                               type="number"/>
                        <div class="input-group-append">
                            <span class="input-group-text"><i class="far fa-euro-sign"></i></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card border-light rounded-1 mt-4">
        <div class="card-body">
            <h5 class="card-title text-secondary">Facture justificative<small>*</small></h5>
            <div class="p-2">
                <div class="form-group">
                    <div>
                        Votre facture doit :
                        <ul>
                            <li>être nominative et acquittée</li>
                            <li>préciser la nature de la dépense</li>
                            <li>faire apparaître le nom du marchand</li>
                        </ul>
                        <p><b>Les tickets de carte bancaire ne sont pas acceptés</b></p>
                    </div>
                    <div *ngFor="let file of files; let myIndex = index">
                        <div class="p-2" style="color: green">
                            {{ file }}
                            <button (click)="removeDocument(myIndex)" [disabled]="isSaving"
                                    class="btn btn-sm btn-danger ml-2" type="button">
                                <i class="far fa-trash fa-fw"></i>
                            </button>
                        </div>
                    </div>
                    <div *ngIf="files.length < 5" class="input-group">
                        <button (filePick)="pickDocument($event)"
                                [disabled]="isSaving"
                                class="btn btn-sm btn-primary file-input"
                                data-cy="btnUploadJustificatif"
                                id="file1"
                                ngxFilePicker
                                type="button">
                            <i class="far fa-paperclip fa-fw"></i>Ajouter un justificatif
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card border-light rounded-1 mt-4">
        <div class="card-body">
            <h5 class="card-title text-secondary">Compte bancaire<small>*</small></h5>
            <div class="p-2">
                <div class="form-group">
                    <p>
                        Pour effectuer le remboursement
                    </p>
                    <div *ngIf="ibanFile === null; else hasIbanFile" class="input-group">
                        <button (filePick)="pickBeneficiary($event)"
                                [disabled]="isSaving"
                                class="btn btn-sm btn-primary file-input"
                                data-cy="btnSelectBeneficiary"
                                id="ibanFile"
                                ngxFilePicker
                                type="button">
                            <i class="far fa-paperclip fa-fw"></i>Ajouter mon RIB
                        </button>
                    </div>

                    <ng-template #hasIbanFile>
                        <div class="p-2" style="color: green">
                            {{ ibanFile }}
                            <button (click)="removeBeneficiary()" [disabled]="isSaving"
                                    class="btn btn-sm btn-danger ml-2"
                                    type="button">
                                <i class="far fa-trash fa-fw"></i>
                            </button>
                        </div>
                    </ng-template>
                    <!--<div [style]="ibanFile === null ? 'color: #EE4C4C' : 'color: green'" class="p-2">
                        {{ ibanFile === null ? 'Aucun RIB ajouté' : ibanFile }}
                    </div>-->
                </div>
            </div>
        </div>
    </div>
    <div class="form-group mt-4">
        <button
            [disabled]="!refundForm.valid || (reclamationForm !== null && !reclamationForm.valid && files.length > 0 && ibanFile !== null) || isSaving"
            [promiseBtn]="isSaving"
            class="btn btn-primary btn-block rounded-pill"
            type="submit">
            Envoyer
        </button>
        <small *ngIf="!refundForm.valid">Demander un remboursement nécessite un justificatif (facture,
            ticket de
            caisse), un compte bancaire et un montant indiqué.</small>
    </div>
</form>
