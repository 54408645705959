<div class="container-fluid text-center bg-white pt-4 pb-2">
    <div>
        <div class="bg-primary-light text-primary rounded-circle"
             style="display: inline-block; padding: 1rem; width: 112px; height: 112px; line-height: 112px;">
            <i class="far fa-user fa-3x"></i>
        </div>
    </div>
    <h5 class="mt-2">
        {{cseEmploye.title}}. {{cseEmploye.firstname}} {{cseEmploye.lastname}}
    </h5>
    <a [routerLink]="['/auth/logout']" class="btn rounded-pill btn-outline-secondary">Se déconnecter</a>
</div>
