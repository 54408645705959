import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AuthService} from '@cseemploye/app/auth/services/auth.service';
import {WelcomeComponent} from '@cseemploye/app/welcome/pages/welcome/welcome.component';
import {environment} from '@cseemploye/environments/environment';
import {CseEmploye} from '@mcv/core';
import {Intercom} from 'ng-intercom';
import {MessagingService} from '@cseemploye/service/messaging.service';
import {McvnotifierService} from '@mcv/ui';
import {Subscription} from 'rxjs';

@Component({
    selector: 'mcv-cse-employe-index',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit, OnDestroy {
    currentUser: CseEmploye;
    isAdmin = false;
    notificationSubscription: Subscription;

    constructor(private intercom: Intercom,
                private dialog: MatDialog,
                private authService: AuthService,
                private messagingService: MessagingService,
                private notifierService: McvnotifierService) {
    }

    ngOnInit(): void {
        this.launchWelcomeScreen();
        this.isAdmin = this.authService.superAdmin;
        this.currentUser = this.authService.user;
        if (this.isAdmin) {
            this.intercom.shutdown();
        } else {
            this.intercom.boot({
                user_hash: this.currentUser.user_hash,
                name: `${this.currentUser.firstname} ${this.currentUser.lastname}`,
                user_id: this.currentUser.public_id,
                email: this.currentUser.email,
                mobile: this.currentUser.mobile,
                phone: this.currentUser.mobile,
                cse: this.currentUser.cse?.legalName
            });
        }
        this.messagingService.requestPermission();
        this.messagingService.receiveMessage();
        this.notificationSubscription = this.messagingService.currentMessage.subscribe(value => {
            if (value != null) {
                this.notifierService.success(`
${value.notification.title}
${value.notification.body}
`);
            }
        });
    }

    ngOnDestroy() {
        if (this.notificationSubscription) {
            this.notificationSubscription.unsubscribe();
        }
    }

    async launchWelcomeScreen() {
        const keyflag = window.localStorage.getItem('mcv_' + environment.client_env + '_welcome');
        if (keyflag === null) {
            const dialogRef = this.dialog.open(WelcomeComponent,
                {
                    panelClass: 'welcome--container__size',
                    hasBackdrop: true,
                    maxWidth: null,
                    disableClose: true
                });
            await dialogRef.afterClosed()
                .toPromise();
            window.localStorage.setItem('mcv_' + environment.client_env + '_welcome', '1');
        }
    }
}
