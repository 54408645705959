import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '@cseemploye/app/auth/services/auth.service';
import {CseEmployeService} from '@mcv/coreservices';
import {McvnotifierService} from '@mcv/ui';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {map} from 'rxjs/operators';

@Component({
    selector: 'mcv-modal-lost-password',
    templateUrl: './modal-lost-password.component.html',
    styleUrls: ['./modal-lost-password.component.scss']
})
export class ModalLostPasswordComponent implements OnInit {
    loading = false;
    anonymousEmails: any;
    lostPasswordForm: FormGroup = this.fb.group({
        email: [null, [Validators.required]],
        target: ['email']
    });
    step = 1;

    constructor(private fb: FormBuilder,
                public modalRef: BsModalRef,
                private cseEmployeService: CseEmployeService,
                private notifierService: McvnotifierService,
                private authService: AuthService) {
    }

    ngOnInit(): void {
    }

    async sendLostPassword(): Promise<any> {
        try {
            this.loading = true;
            this.anonymousEmails = await this.cseEmployeService
                .displayAnonymousEmail(this.lostPasswordForm.get('email').value)
                .pipe(map(r => r.data))
                .toPromise();
            if (this.anonymousEmails.email_alt) {
                this.step = 2;
            } else {
                await this.sendLostPasswordWithTarget();
            }
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.loading = false;
        }
    }

    async sendLostPasswordWithTarget() {
        try {
            this.loading = true;
            await this.authService
                .resetPassword(this.lostPasswordForm.value)
                .toPromise();
            this.notifierService.success('Si l\'adresse existe un email vient de vous être envoyé.');
            this.modalRef.hide();
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.loading = false;
        }
    }
}
