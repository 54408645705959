import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BASEURL} from '@mcv/config';
import {Observable} from 'rxjs';
import {SingleResult} from '@mcv/core';

@Injectable({
    providedIn: 'root'
})
export class DeviceTokenService {

    private model = 'notification';

    constructor(protected httpClient: HttpClient, @Inject(BASEURL) protected baseUrl: string) {
    }

    register(form: any): Observable<SingleResult<any>> {
        return this.httpClient.post<SingleResult<any>>(`${this.baseUrl}/${this.model}/register`, form);
    }

    login(form: any): Observable<SingleResult<any>> {
        return this.httpClient.post<SingleResult<any>>(`${this.baseUrl}/${this.model}/login`, form);
    }

    logout(form: any): Observable<SingleResult<any>> {
        return this.httpClient.post<SingleResult<any>>(`${this.baseUrl}/${this.model}/logout`, form);
    }

    unregister(form: any): Observable<SingleResult<any>> {
        return this.httpClient.post<SingleResult<any>>(`${this.baseUrl}/${this.model}/unregister`, form);
    }
}
