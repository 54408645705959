<mcv-navbar></mcv-navbar>
<div class="index-container">
    <div *ngIf="isAdmin" class="alert alert-info text-center" role="alert">
        <i aria-hidden="true" class="far fa-exclamation-triangle mr-2"></i> You are logged as {{currentUser.fullname}}
        ({{currentUser.cse?.legalName}}),
        <a [routerLink]="'/auth/logout'" class="alert-link">click here to log out</a>
        <i aria-hidden="true" class="far fa-exclamation-triangle ml-2"></i>
    </div>
    <mcv-outsider-warning *ngIf="currentUser.outsider"></mcv-outsider-warning>
    <router-outlet></router-outlet>
</div>
