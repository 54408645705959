import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '@cseemploye/app/auth/services/auth.service';
import {ConfigurationsService} from '@cseemploye/app/auth/services/configurations.service';
import {AuthResult} from '@mcv/core';
import {McvnotifierService} from '@mcv/ui';

@Component({
    selector: 'mcv-logas',
    templateUrl: './logas.component.html',
    styleUrls: ['./logas.component.scss']
})
export class LogasComponent implements OnInit {

    constructor(private authService: AuthService,
                private router: Router,
                private notifierService: McvnotifierService,
                private configurationService: ConfigurationsService,
                private activatedRoute: ActivatedRoute) {
    }

    async ngOnInit(): Promise<void> {
        const token: string = this.activatedRoute.snapshot.paramMap.get('id');
        try {
            if (token) {
                this.authService.logout();
                const jwt: AuthResult = await this.authService
                    .logAs(token)
                    .toPromise();
                this.authService.setSession(jwt);
                await this.configurationService
                    .loadAppConfig()
                    .toPromise();
            }
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.router.navigate(['/']);
        }
    }

}
