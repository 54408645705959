import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {AuthService} from '@cseemploye/app/auth/services/auth.service';
import {BASEURL} from '@mcv/config';

@Component({
    selector: 'mcv-step3',
    templateUrl: './step3.component.html',
    styleUrls: ['./step3.component.scss']
})
export class Step3Component implements OnInit {
    @Output() nextStep: EventEmitter<boolean> = new EventEmitter<boolean>();

    hasGiftBudget = true;

    constructor(
        @Inject(BASEURL) public baseUrl: string,
        private authService: AuthService
    ) {
    }

    ngOnInit(): void {
        this.hasGiftBudget = this.authService.user.owned_budget?.indexOf('cadeaux') !== -1;
    }
}
