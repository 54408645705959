import {Component, Input} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {convertToFormData, CseEmploye} from '@mcv/core';
import {CseEmployeReclamationService, RefundRequestsService} from '@mcv/coreservices';
import {McvnotifierService} from '@mcv/ui';
import {ReadFile} from 'ngx-file-helpers';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Router} from '@angular/router';

const MO = 1000 * 1000;

@Component({
    selector: 'mcv-refund-request-form',
    templateUrl: './refund-request-form.component.html',
    styleUrls: ['./refund-request-form.component.scss']
})
export class RefundRequestFormComponent {

    isSaving = false;
    @Input() cseEmploye: CseEmploye;
    @Input() reclamationForm?: FormGroup = null;
    modalRef: BsModalRef;
    ibanFile = null;
    filesToUpload = [];
    files: string[] = [];
    refundForm: FormGroup = this.fb.group({
        file1: [null],
        file2: [null],
        file3: [null],
        file4: [null],
        file5: [null],
        ibanFile: [null, [Validators.required]],
        amount: [null, [Validators.required, Validators.max(1000000)]],
        pdc_comment: [null]
    });

    constructor(private fb: FormBuilder,
                private router: Router,
                private refundRequestService: RefundRequestsService,
                private cseEmployeReclamationService: CseEmployeReclamationService,
                private notifierService: McvnotifierService,
                private modalService: BsModalService) {
    }

    async addRefund(): Promise<void> {
        try {
            this.isSaving = true;
            this.cseEmployeReclamationService.autoRedirect = false;
            if (this.reclamationForm?.valid === true) {
                const data = {
                    ...this.reclamationForm.value, ...this.refundForm.value
                };
                data.pdc_comment = this.reclamationForm?.value.reclamation;
                data.file1 = this.filesToUpload[0];
                data.file2 = this.filesToUpload[1] ?? null;
                data.file3 = this.filesToUpload[2] ?? null;
                data.file4 = this.filesToUpload[3] ?? null;
                data.file5 = this.filesToUpload[4] ?? null;
                await this.cseEmployeReclamationService.add(convertToFormData(data)).toPromise();
            } else {
                await this.refundRequestService.add(convertToFormData(this.refundForm.value)).toPromise();
            }
            this.notifierService.success('Votre réclamation avec demande de remboursement a bien été envoyée');
            this.router.navigate(['/activation/aide/reclamation_ok']);
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.isSaving = false;
        }
    }

    pickDocument($event: ReadFile): void {
        if ($event.size > 60 * MO) {
            this.notifierService.error('La taille du fichier ne doit pas dépasser 60 Mo');
            return;
        }
        this.filesToUpload.push($event.underlyingFile);
        this.files.push($event.underlyingFile.name);
    }

    pickBeneficiary($event: ReadFile): void {
        if ($event.size > 60 * MO) {
            this.notifierService.error('La taille du fichier ne doit pas dépasser 60 Mo');
            return;
        }
        this.refundForm.patchValue({ibanFile: $event.underlyingFile});
        this.ibanFile = $event.underlyingFile.name;
    }

    removeDocument(index: number): void {
        this.filesToUpload.splice(index, 1);
        this.files.splice(index, 1);
    }

    removeBeneficiary(): void {
        this.refundForm.patchValue({ibanFile: null});
        this.ibanFile = null;
    }
}
