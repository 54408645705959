import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@cseemploye/environments/environment';
import { AppModule } from './app/app.module';
import Bugsnag from '@bugsnag/js';
import version from './version.json';
import { ServiceLocator } from '@mcv/coreservices';
import { AuthService } from '@cseemploye/app/auth/services/auth.service';

Bugsnag.start({
    apiKey: 'e5bfffe6efa189c2f55a67ce165ee5e0',
    appVersion: version.version,
    releaseStage: environment.client_env,
    onError: function (event) {
        const authService = ServiceLocator.injector.get(AuthService);
        event.setUser(authService.user.public_id, authService.user.email, authService.user.fullname);
    }
});

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
