<ng-container *ngIf="cseEmploye$ | async as cseEmploye; else loading">
    <mcv-profile-top [cseEmploye]="cseEmploye"></mcv-profile-top>
    <div class="bg-back">
        <div class="container py-4">
            <h4 class="text-primary">Préférences de communication</h4>
            <mcv-profile-preference [cseEmploye]="cseEmploye"></mcv-profile-preference>
            <mcv-profile-abo [cseEmploye]="cseEmploye"></mcv-profile-abo>
            <h4 class="text-primary my-4">Mes informations personnelles</h4>
            <mcv-profile-personal [cseEmploye]="cseEmploye"></mcv-profile-personal>
<!--            <mcv-bank-account [cseEmploye]="user"></mcv-bank-account>-->
            <mcv-profile-password [askOldPassword]="true"
                                  [cseEmploye]="cseEmploye"
                                  [updating]="true"
                                  submitLabel="Mettre à jour mon mot de passe"></mcv-profile-password>
            <mcv-legal-about></mcv-legal-about>
        </div>
    </div>
</ng-container>
<ng-template #loading>
    <div class="d-flex align-items-center justify-content-center" style="height: 100vh">
        <mat-progress-spinner mode="indeterminate">
        </mat-progress-spinner>
    </div>
</ng-template>
