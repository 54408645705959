<form (ngSubmit)="saveForm()" [formGroup]="fakeForm" data-cy="formupload">
    <button (filePick)="pickFile($event)"
            [disabled]="uploading"
            [promiseBtn]="uploading"
            class="{{classBtn}}"
            data-cy="btnUploadJustificatif"
            ngxFilePicker
            tooltip="{{'Envoyer le justificatif'}}"
            type="button">
        <i class="{{classIcn}}"></i> {{buttonText}}
    </button>
    <input class="d-none" data-cy="inputFile" formControlName="file" type="file"/>
</form>
