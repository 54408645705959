import {Pipe, PipeTransform} from '@angular/core';
import {Transaction} from '@mcv/core';

@Pipe({
    name: 'displayJustificatif'
})
export class DisplayJustificatifPipe implements PipeTransform {

    transform(value: Transaction, ...args: unknown[]): boolean {
        return value.cardtransaction && value.amount < 0;
    }

}
