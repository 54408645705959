<div class="bg-back">
    <div class="container">
        <div class="card border-light rounded-1 bg-white">
            <div class="card-body">
                <h3 class="card-title">
                    <div class="float-right">
                        <button (click)="printTest()"
                                class="btn btn-sm btn-outline-primary rounded-pill border-0 bg-primary-light">
                            Imprimer
                        </button>
                    </div>
                </h3>
            </div>
            <div id="detail">
                <table class="detail-table" style="font-weight: 400;">
                    <thead>
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Nature de l'opération</th>
                        <th scope="col" style="text-align: center">Débit</th>
                        <th scope="col" style="text-align: center">Crédit</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngIf="transactions | nTransaction:'last' as transaction">
                        <td colspan="2" style="text-align: right;">
                            SOLDE PRÉCÉDENT AU
                            <span>
                            {{transaction.tm | date:'dd/MM/yyyy' | titlecase}}
                        </span>
                        </td>
                        <td></td>
                        <td style="text-align: center; vertical-align: middle;">
                            {{ transaction | getSolde | money}}
                        </td>
                    </tr>
                    <ng-container *ngFor="let transaction of (transactions | sortByDate:'tm')">
                        <tr class="">
                            <td style="vertical-align: middle;">
                                <div>
                                    {{transaction.tm | date:'EEEE dd MMMM' | titlecase}}
                                </div>
                            </td>
                            <td style="vertical-align: middle;">
                                <div>
                                    {{transaction.label}}
                                </div>
                            </td>
                            <td style="text-align: center; vertical-align: middle;">
                                <ng-container *ngIf="transaction.amount <= 0">
                                    {{transaction.amount | abs | money}}
                                </ng-container>
                            </td>
                            <td style="text-align: center; vertical-align: middle;">
                                <ng-container *ngIf="transaction.amount > 0">
                                    {{transaction.amount | money}}
                                </ng-container>
                            </td>
                        </tr>
                    </ng-container>
                    <tr>
                        <td colspan="2" style="text-align: right;">
                            TOTAUX DES MOUVEMENTS
                        </td>
                        <td style="text-align: center; vertical-align: middle;">
                            {{transactions | sumof:'amount':'negative'|abs|money}}
                        </td>
                        <td style="text-align: center; vertical-align: middle;">
                            {{transactions | sumof:'amount':'positive'|abs|money}}
                        </td>
                    </tr>
                    <tr *ngIf="transactions | nTransaction:'first' as transaction">
                        <td colspan="2" style="text-align: right;">
                            NOUVEAU SOLDE AU {{transaction.tm | date:'dd/MM/yyyy' | titlecase}}
                        </td>
                        <td style="text-align: center; vertical-align: middle;">
                        </td>
                        <td style="text-align: center; vertical-align: middle;">
                            {{(transactions[transactions.length - 1] | getSolde) + ((transactions | sumof:'amount':'positive'|abs) - (transactions | sumof:'amount':'negative'|abs)) | money}}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="m-5">&nbsp;</div>
</div>
