import {Component, Inject, OnInit} from '@angular/core';
import {environment} from '@cseemploye/environments/environment';
// @ts-ignore
import * as version from '@cseemploye/version.json';
import {BASEURL} from '@mcv/config';
import {Cgu} from '@mcv/core';
import {CguService} from '@mcv/coreservices';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {WelcomeComponent} from '@cseemploye/app/welcome/pages/welcome/welcome.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'mcv-legal-about',
    templateUrl: './legal-about.component.html',
    styleUrls: ['./legal-about.component.scss']
})
export class LegalAboutComponent implements OnInit {
    MENTIONS_MACARTEVACANCES = environment.MENTIONS_MACARTEVACANCES;
    POLITIQUE_MACARTEVACANCES = environment.POLITIQUE_MACARTEVACANCES;
    version: any = version;
    cgu$: Observable<Cgu>;

    constructor(@Inject(BASEURL) public baseUrl: string,
                private cguService: CguService,
                private dialog: MatDialog) {
        this.cgu$ = this.cguService.getLastCguPdc()
            .pipe(map(r => r.data));
    }

    ngOnInit(): void {
    }

    showCarousel(): void {
        this.dialog.open(WelcomeComponent,
            {
                panelClass: 'welcome--container__size',
                hasBackdrop: true,
                maxWidth: null,
                disableClose: true
            });
    }

}
