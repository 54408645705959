import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BASEURL } from '@mcv/config';
import { BaseRepository, RefundRequest, SingleResult } from '@mcv/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RefundRequestsService extends BaseRepository<RefundRequest>  {
    model = 'refund-request';

    constructor(protected httpClient: HttpClient, @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl);
    }
}
