import {Component, OnInit} from '@angular/core';
import {AuthService} from '@cseemploye/app/auth/services/auth.service';
import {CseEmploye} from '@mcv/core';
import {CseEmployeService} from '@mcv/coreservices';
import {McvnotifierService} from '@mcv/ui';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Component({
    selector: 'mcv-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
    cseEmploye$: Observable<CseEmploye>;
    user: CseEmploye;

    constructor(private cseEmployeService: CseEmployeService,
                private notifierService: McvnotifierService,
                private droitsService: AuthService) {
    }

    ngOnInit(): void {
        this.user = this.droitsService.user;
        this.cseEmploye$ = this.cseEmployeService
            .view(this.user.public_id)
            .pipe(
                map(r => r.data),
                catchError((e, caught) => {
                    this.notifierService.error(e);
                    return throwError(e);
                })
            );
    }
}
