<form (ngSubmit)="checkPassword(confirmPasswordModale)" [formGroup]="persoForm">
    <div class="card border-light rounded-1 mt-4">
        <div class="card-body">
            <h5 class="card-title text-secondary">Mes informations</h5>
            <mcv-user-profile-info [formGroup]="persoForm"></mcv-user-profile-info>
            <mcv-user-profile-address [formGroup]="persoForm" [useGeoSearching]="false"></mcv-user-profile-address>
            <div class="form-row mt-3">
                <div class="col-md-6 offset-md-3">
                    <button [disabled]="!persoForm.valid || saving"
                            [promiseBtn]="saving"
                            class="btn btn-primary rounded-pill btn-block"
                            type="submit">
                        Valider
                    </button>
                </div>
            </div>
            <div class="form-row">
                <div class="col">
                    <small>*: mention obligatoire</small>
                </div>
            </div>
        </div>
    </div>
</form>

<ng-template #confirmPasswordModale>
    <div class="modal-header">
        <h5 class="modal-title">Confirmer votre mot de passe</h5>
        <button (click)="hide()" aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form (ngSubmit)="confirmPassword()" [formGroup]="confirmPasswordForm">
            <p>
                Merci de confirmer votre mot de passe pour modifier vos informations personnelles.
            </p>
            <div class="form-group">
                <label class="control-label" for="password">Mot de passe :</label>
                <mcv-password-input autocomplete="password"
                                    data-cy="password"
                                    formControlName="password"
                                    id="password"
                                    name="password"></mcv-password-input>
            </div>

            <div class="form-group">
                <button class="btn btn-primary btn-block" type="submit">Valider le mot de passe</button>
            </div>

            <div class="form-group">
                <button (click)="hide()" class="btn btn-outline-secondary btn-block" type=button>
                    Annuler
                </button>
            </div>
        </form>
    </div>
</ng-template>
