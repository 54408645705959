import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CseEmployeService } from '@mcv/coreservices';
import { CseEmploye } from '@mcv/core';
import { McvnotifierService, mobilePhoneValidator } from '@mcv/ui';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'mcv-profile-preference',
    templateUrl: './profile-preference.component.html',
    styleUrls: ['./profile-preference.component.scss']
})
export class ProfilePreferenceComponent implements OnInit {
    @Input() cseEmploye: CseEmploye;
    sendingCodeMailConfirmation = false;
    sendingCodeMobileConfirmation = false;
    mailForm: FormGroup = this.fb.group({
        public_id: [],
        email_alt: [null, [Validators.email]],
        password_check: []
    });
    mobileForm: FormGroup = this.fb.group({
        public_id: [],
        mobile: [null, [Validators.required, mobilePhoneValidator()]],
        password_check: []
    });
    modalRef: BsModalRef;

    confirmCodeForm: FormGroup = this.fb.group({
        public_id: [],
        code: [],
        type: []
    });
    confirmPasswordForm: FormGroup = this.fb.group({
        public_id: [],
        password: []
    });
    checkPasswordKey = '';
    message: string;
    title: string;

    constructor(private fb: FormBuilder,
                private modalService: BsModalService,
                private notifierService: McvnotifierService,
                private cseEmployeService: CseEmployeService) {
    }

    ngOnInit(): void {
        this.mailForm.patchValue({
            public_id: this.cseEmploye.public_id,
            email_alt: this.cseEmploye.email_alt
        });
        this.mobileForm.patchValue({
            public_id: this.cseEmploye.public_id,
            mobile: this.cseEmploye.mobile
        });
        if (!this.cseEmploye.email_alt || this.cseEmploye.email_alt.length === 0) {
            this.mailForm.get('email_alt')
                .setValidators([Validators.required, Validators.email]);
        }
    }

    checkPassword(confirmPasswordModale: TemplateRef<any>, key: string): void {
        this.modalRef = this.modalService.show(confirmPasswordModale, { class: 'modal-dialog-centered modal-lg' });
        this.checkPasswordKey = key;
    }

    async saveMailForm(confirmCodeModale: TemplateRef<any>): Promise<void> {
        return;
        if (this.sendingCodeMailConfirmation) {
            return;
        }
        try {
            this.sendingCodeMailConfirmation = true;
            await this.cseEmployeService.askNewmail(this.mailForm.value)
                .toPromise();
            this.confirmCodeForm
                .patchValue({
                    public_id: this.cseEmploye.public_id,
                    type: 'mail',
                    code: null
                });
            const mail = this.mailForm.value.email_alt ? this.mailForm.value.email_alt : this.cseEmploye.email_alt;
            this.message = `Un code vient d'être envoyé sur l'email ${mail}, veuillez le saisir ci-dessous`;
            this.title = 'Mise à jour de votre email personnel';
            this.modalRef = this.modalService.show(confirmCodeModale, { class: 'modal-dialog-centered modal-lg' });
        } catch (e) {
            // 403 error already catch by the interceptor
            if (e.status !== 403) {
                this.notifierService.error(e);
            }

            this.ngOnInit();
        } finally {
            this.sendingCodeMailConfirmation = false;
        }
    }

    async saveMobileForm(dialog: TemplateRef<any>): Promise<void> {
        return;
        if (this.sendingCodeMobileConfirmation) {
            return;
        }
        try {
            this.sendingCodeMobileConfirmation = true;
            await this.cseEmployeService
                .askNewmobile(this.mobileForm.value)
                .toPromise();
            this.confirmCodeForm
                .patchValue({
                    public_id: this.cseEmploye.public_id,
                    type: 'mobile',
                    code: null
                });
            const mobileNumber = this.mobileForm.value.mobile ? this.mobileForm.value.mobile : this.cseEmploye.mobile;
            this.message = `Un code vient d'être envoyé sur le numéro de mobile ${mobileNumber}, veuillez le saisir ci-dessous`;
            this.title = 'Mise à jour de votre numéro de mobile';
            this.modalRef = this.modalService.show(dialog, { class: 'modal-dialog-centered modal-lg' });
        } catch (e) {
            // 403 error already catch by the interceptor
            if (e.status !== 403) {
                this.notifierService.error(e);
            }
            this.ngOnInit();
        } finally {
            this.sendingCodeMobileConfirmation = false;
        }
    }

    async confirmCode(): Promise<void> {
        try {
            await this.cseEmployeService
                .confirmCode(this.confirmCodeForm.value)
                .toPromise();
            this.modalRef.hide();
            this.notifierService.success('Modification effectuée !');
        } catch (e) {
            this.notifierService.error(e);
        }
    }

    confirmPassword(confirmCodeModale: TemplateRef<any>): void {
        if (this.checkPasswordKey === 'mail') {
            this.mailForm.patchValue({
                password_check: this.confirmPasswordForm.value.password
            });
            this.saveMailForm(confirmCodeModale);
        } else if (this.checkPasswordKey === 'mobile') {
            this.mobileForm.patchValue({
                password_check: this.confirmPasswordForm.value.password
            });
            this.saveMobileForm(confirmCodeModale);
        }
        this.hide();
    }

    hide(): void {
        this.confirmPasswordForm.patchValue({ password: '' });
        this.modalRef.hide();
    }

}
