import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AuthService } from '@cseemploye/app/auth/services/auth.service';
import { BASEURL } from '@mcv/config';

@Component({
    selector: 'mcv-step5',
    templateUrl: './step5.component.html',
    styleUrls: ['./step5.component.scss']
})
export class Step5Component implements OnInit {

    @Output() nextStep: EventEmitter<boolean> = new EventEmitter<boolean>();

    hasGiftBudget = true;

    constructor(
        @Inject(BASEURL) public baseUrl: string,
        private authService: AuthService
    ) {}

    ngOnInit(): void {
        this.hasGiftBudget = this.authService.user.has_budget;
    }

}
