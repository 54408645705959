import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoutComponent } from '@cseemploye/app/auth/pages/logout/logout.component';
import { ClientModule } from '@cseemploye/app/client/client.module';
import { UiModule } from '@mcv/ui';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './pages/login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthService } from './services/auth.service';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ValdemortModule } from 'ngx-valdemort';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { IndexComponent } from './pages/index/index.component';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from '@cseemploye/environments/environment';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { SharedModule } from '../shared/shared.module';
import { ConfigurationsService } from './services/configurations.service';
import { LogasComponent } from './pages/logas/logas.component';
import { ModalLostPasswordComponent } from './ui/modal-lost-password/modal-lost-password.component';
import { ResetLinkExpiredComponent } from './pages/reset-link-expired/reset-link-expired.component';
import { ResetLinkInvalidComponent } from './pages/reset-link-invalid/reset-link-invalid.component';

const configFactory = (configurationsService: ConfigurationsService, authService: AuthService): () => Promise<any> => {
    return () => {
        if (authService.isLoggedIn()) {
            return Promise.all([
                configurationsService.loadAppConfig()
                    .toPromise()
            ]);
        }
    };
};

@NgModule({
    declarations: [
        LoginComponent,
        ResetPasswordComponent,
        IndexComponent,
        LogasComponent,
        ModalLostPasswordComponent,
        LogoutComponent,
        ResetLinkExpiredComponent,
        ResetLinkInvalidComponent
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        AuthRoutingModule,
        ReactiveFormsModule,
        ValdemortModule,
        UiModule,
        SharedModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        ClientModule
    ],
    providers: [
        AuthService,
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: environment.RECAPTCHA_SITE_KEY
            } as RecaptchaSettings
        },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        {
            provide: APP_INITIALIZER,
            useFactory: configFactory,
            deps: [
                ConfigurationsService,
                AuthService
            ],
            multi: true
        }
    ]
})
export class AuthModule {
}
