import {CommonModule, CurrencyPipe} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {
    CardAskPublicTokenComponent
} from '@cseemploye/app/shared/components/card-ask-public-token/card-ask-public-token.component';
import {
    CseEmployePersonalDataConfirmComponent
} from '@cseemploye/app/shared/components/cse-employe-personal-data-confirm/cse-employe-personal-data-confirm.component';
import {FaIconPipe} from '@cseemploye/app/shared/pipes/fa-icon.pipe';
import {MonthYearNamePipe} from '@cseemploye/app/shared/pipes/month-year-name.pipe';
import {ProfilePasswordComponent} from '@cseemploye/app/shared/ui/profile-password/profile-password.component';
import {UiModule} from '@mcv/ui';
import {IMaskModule} from 'angular-imask';
import {AlertModule} from 'ngx-bootstrap/alert';
import {defineLocale} from 'ngx-bootstrap/chronos';
import {frLocale} from 'ngx-bootstrap/locale';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {NgxFileHelpersModule} from 'ngx-file-helpers';
import {AbsPipe} from './pipes/abs.pipe';
import {IbanPipe} from './pipes/iban.pipe';
import {SortByDatePipe} from './pipes/sort-by-date.pipe';
import {SumofPipe} from './pipes/sumof.pipe';
import {ToDateObjPipe} from './pipes/to-date-obj.pipe';
import {PagingService} from './services/paging.service';
import {JustificatifButtonUploadComponent} from './ui/justificatif-button-upload/justificatif-button-upload.component';
import {StepperProgressComponent} from './ui/stepper-progress/stepper-progress.component';
import {UiCardComponent} from './ui/ui-card/ui-card.component';

defineLocale('fr', frLocale);

@NgModule({
    declarations: [
        IbanPipe,
        FaIconPipe,
        MonthYearNamePipe,
        AbsPipe,
        SumofPipe,
        SortByDatePipe,
        ToDateObjPipe,
        JustificatifButtonUploadComponent,
        ProfilePasswordComponent,
        StepperProgressComponent,
        CardAskPublicTokenComponent,
        CseEmployePersonalDataConfirmComponent,
        UiCardComponent
    ],
    imports: [
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        CommonModule,
        MatProgressSpinnerModule,
        PaginationModule,
        UiModule,
        NgxFileHelpersModule
    ],
    exports: [
        AlertModule,
        CommonModule,
        PaginationModule,
        ReactiveFormsModule,
        FormsModule,
        MatProgressSpinnerModule,
        IMaskModule,
        IbanPipe,
        AbsPipe,
        FaIconPipe,
        SumofPipe,
        MonthYearNamePipe,
        SortByDatePipe,
        ToDateObjPipe,
        JustificatifButtonUploadComponent,
        ProfilePasswordComponent,
        StepperProgressComponent,
        CardAskPublicTokenComponent,
        CseEmployePersonalDataConfirmComponent,
        UiCardComponent
    ],
    providers: [
        PagingService,
        CurrencyPipe
    ]
})
export class SharedModule {
}
