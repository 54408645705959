import {Injectable} from '@angular/core';
import {AngularFireRemoteConfig} from '@angular/fire/remote-config';
import {environment} from '@cseemploye/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagsService {
    constructor(private remoteConfig: AngularFireRemoteConfig) {
        this.remoteConfig.fetchAndActivate();

        // this.remoteConfig.changes.pipe(
        //     filterFresh(172_800_000), // ensure we have values from at least 48 hours ago
        //     first(),
        //     scanToObject(environment.firebase.remoteConfig.defaults)
        // ).subscribe(config => {
        //
        //     console.log("first one", {config})
        // });
        //
        // this.remoteConfig.booleans.subscribe(bools => {
        //     this.kycStatus = bools[environment.firebase.remoteConfig.flags.kycLiveness];
        //     console.log(this.kycStatus);
        // });
        // //
        // // this.remoteConfig.booleans.kycliveness_enabled.subscribe(a => {debugger;})
        // //
        // // this.remoteConfig.changes.pipe(
        // //     // filter(param => param.key === 'titleBackgroundColor'),
        // //     // map(param => param.asString())
        // //     // last()
        // // ).subscribe(stuff => {debugger})

    }

    async isKycLivenessEnabled(): Promise<boolean> {
        await this.remoteConfig.ensureInitialized();
        return this.remoteConfig.getBoolean(environment.featureFlagsNames.kycLiveness);
    }

    async isRefundEnabled(): Promise<boolean> {
        await this.remoteConfig.ensureInitialized();
        return this.remoteConfig.getBoolean(environment.featureFlagsNames.refundEnabled);
    }
}
