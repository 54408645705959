import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'mcv-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
    step = 1;

    constructor(public dialogRef: MatDialogRef<WelcomeComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
    }

    close() {
        this.dialogRef.close();
    }

    nextStep() {
        this.step++;
        if (this.step > 5) {
            this.close();
        }
    }
}
