import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalLostPasswordComponent } from '@cseemploye/app/auth/ui/modal-lost-password/modal-lost-password.component';
import { McvnotifierService } from '@mcv/ui';
import { AuthService } from 'apps/cseemploye/src/app/auth/services/auth.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigurationsService } from '../../services/configurations.service';

@Component({
    selector: 'mcv-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
    loginForm: FormGroup = this.fb.group({
        email: [null, [Validators.required]],
        password_hash: [null, [Validators.required]],
        recaptchaReactive: [],
        remember_me: []
    });
    modalRef: BsModalRef;
    saving = false;
    subscription: Subscription = new Subscription();
    improveSecurity = false;
    isLogin = false;
    error;
    redirectTo: string;

    constructor(private fb: FormBuilder,
                private router: Router,
                private route: ActivatedRoute,
                private modalService: BsModalService,
                private configurationService: ConfigurationsService,
                private notifierService: McvnotifierService,
                private authService: AuthService) {
        this.redirectTo = this.route.snapshot.queryParams['redirectTo'] || '/client/transaction/index';
    }

    async ngOnInit(): Promise<void> {
        // if (await this.authService.checkIp()
        //     .pipe(map(r => r.data.mustCaptcha))
        //     .toPromise()) {
        //     return this.reinforceSecurity();
        // }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    login(): void {
        this.isLogin = true;
        this.authService
            .login(this.loginForm.value)
            .toPromise()
            .then(_ => {
                return this.configurationService.loadAppConfig()
                    .toPromise();
            })
            .then(_ => {
                this.isLogin = false;
                this.router.navigateByUrl(this.redirectTo);
            })
            .catch(err => {
                this.isLogin = false;
                this.error = err;
                //this.reinforceSecurity();
            });
    }

    reinforceSecurity() {
        //this.improveSecurity = true;
        //this.loginForm.get('recaptchaReactive')
        //    .setValidators([Validators.required]);
    }

    openLostPasswordDialog($event: MouseEvent): void {
        $event.preventDefault();
        this.modalService.show(ModalLostPasswordComponent, { class: 'modal-dialog-centered modal-lg' });
    }
}
