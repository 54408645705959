import {HttpClient, HttpParams} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {BASEURL} from '@mcv/config';
import {BaseRepository, Contribution, ListItem, Result, SingleResult} from '@mcv/core';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {AuthService} from '@cseemploye/app/auth/services/auth.service';
import {SessionStorageService} from '@cseemploye/app/activation/services/SessionStorage.service';

@Injectable({
    providedIn: 'root'
})
export class ContributionService extends BaseRepository<Contribution> {
    model = 'contribution';

    constructor(
        protected httpClient: HttpClient,
        @Inject(BASEURL) baseUrl: string,
        private sessionStorageService: SessionStorageService,
        private authService: AuthService
    ) {
        super(httpClient, baseUrl);
    }

    getPreboardingInfos(): Observable<ListItem<Contribution>> {
        const httpParams: HttpParams = new HttpParams().set('preboarding', '1')
            .set('withCse', '1').set('withTopup', '1');
        return this.getList(1, 999, httpParams);
    }

    getPreboardingContributionCount(): Promise<number> {
        if (this.sessionStorageService.exists('jwt')) {
            this.authService.setSession(this.sessionStorageService.read('jwt'));
            const httpParams: HttpParams = new HttpParams().set('preboarding', '1');
            return this.getCount(httpParams)
                .pipe(
                    tap(_ => this.authService.logout()),
                    map(r => r.data)
                )
                .toPromise();
        }

        return Promise.resolve(-1);
    }

    reloadCount(): Observable<SingleResult<number>> {
        return this.httpClient.get<SingleResult<number>>(`${this.baseUrl}/${this.model}/reloadCount`);
    }

    firstReload(): Observable<SingleResult<Contribution>> {
        return this.httpClient.get<SingleResult<Contribution>>(`${this.baseUrl}/${this.model}/firstReload`);
    }

    reloadList(): Observable<Result<Contribution>> {
        return this.httpClient.get<Result<Contribution>>(`${this.baseUrl}/${this.model}/reload`);
    }

    findWithTopups(id: any): Observable<Result<Contribution>> {
        return this.httpClient.get<Result<Contribution>>(`${this.baseUrl}/${this.model}/index?id=${id}&withTopup=1`);
    }
}
