import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as printJS from 'print-js';
import {DatePipe, TitleCasePipe} from '@angular/common';
import {CseEmploye, Transaction} from '@mcv/core';
import {NTransactionPipe} from '@cseemploye/app/compte/pipes/n-transaction.pipe';
import {AuthService} from '@cseemploye/app/auth/services/auth.service';

@Component({
    selector: 'mcv-transac-detail',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.scss'],
    providers: [TitleCasePipe, NTransactionPipe, DatePipe]
})
export class DetailComponent implements OnInit {
    @Input() count: number;
    @Input() itemsPerPage: number;
    @Input() transactions: Transaction[];
    @Output() changePage: EventEmitter<number> = new EventEmitter<number>();
    cseEmploye: CseEmploye;
    firstLineValue: number;
    lastLineValue: number;

    constructor(private authService: AuthService,
                private nTransactionPipe: NTransactionPipe,
                private datePipe: DatePipe,
                private titleCasePipe: TitleCasePipe) {
    }

    ngOnInit(): void {
        this.cseEmploye = this.authService.user;
    }

    printTest(): void {
        const firstname = this.titleCasePipe.transform(this.cseEmploye.firstname);
        const lastname = this.titleCasePipe.transform(this.cseEmploye.lastname);
        const firsttransaction = this.nTransactionPipe.transform(this.transactions, 'first');
        const transactionDate = this.datePipe.transform(firsttransaction.tm, 'MMMM yyyy');
        printJS({
            printable: 'detail',
            type: 'html',
            scanStyles: false,
            style: `
.detail-table {
width: 100%;
margin-bottom: 1rem;
color: #666666;
}

table {
border-collapse: collapse;
}

.detail-table thead th {
vertical-align: bottom;
border-bottom: 4px solid #dee2e6;
}
.detail-table th, .detail-table td {
padding: 0.75rem;
vertical-align: top;
border-top: 2px solid #dee2e6;
}
            `,
            documentTitle: `Relevé de transactions macartevacances - ${firstname} ${lastname}`,
            header: `${firstname} ${lastname} - ${transactionDate}`
        });
    }
}
