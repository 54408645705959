<div *ngIf="transaction" class="wrapper">
    <div class="cell-sign">
        <div class="square-48 rounded-1 border">
            <span [innerHTML]="transaction.icon | faIcon:'euro-sign'"></span>
        </div>
    </div>
    <div class="cell-label">
        <div>{{transaction.label}}</div>
        <div>
            {{transaction.tm | toDateObj | date:'EEEE dd' | titlecase}}<span *ngIf="transaction.online"> &middot; En ligne</span>
            <span *ngIf="transaction.infos"> &middot; {{transaction.infos}}</span>
        </div>
    </div>
    <div *ngIf="transaction | displayJustificatif" class="cell-jus">
        <mcv-transaction-justificatif [transaction]="transaction">
        </mcv-transaction-justificatif>
    </div>
    <div class="cell-amount">
        <span [ngClass]="{'text-success':transaction.amount>0, credit : transaction.amount>0}" class="ml-1 ml-lg-3">
            {{transaction.amount | money:transaction.currency}}
        </span>
    </div>
</div>
