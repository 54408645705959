import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CseEmployeService } from '@mcv/coreservices';
import { CreatePasswordForm, CseEmploye } from '@mcv/core';
import { McvnotifierService } from '@mcv/ui';

@Component({
    selector: 'mcv-profile-password',
    templateUrl: './profile-password.component.html',
    styleUrls: ['./profile-password.component.scss']
})
export class ProfilePasswordComponent implements OnInit {
    @Input() submitLabel = 'Mettre à jour mon mot de passe';
    @Input() cseEmploye: CseEmploye;
    @Input() saving = false;
    @Input() askOldPassword = false;
    @Input() updating = false;
    @Input() emittingOnly = false;
    @Output() saved: EventEmitter<string> = new EventEmitter<string>();
    @Output() saveChange: EventEmitter<string> = new EventEmitter<string>();
    passForm: FormGroup = CreatePasswordForm(this.fb);

    constructor(private fb: FormBuilder,
                private cseEmployeService: CseEmployeService,
                private notifierService: McvnotifierService) {
    }

    ngOnInit(): void {
        this.passForm.patchValue(this.cseEmploye);
        if (this.askOldPassword) {
            this.addOldPasswordControl();
        }
    }

    async saveForm(): Promise<void> {
        if (this.emittingOnly) {
            this.saveChange.emit(this.passForm.value.password);
            return;
        }
        try {
            this.saving = true;
            await this.cseEmployeService
                .add(this.passForm.value)
                .toPromise();
            this.notifierService.success(`Le mot de passe a été ${this.updating ? 'modifié' : 'créé'}`);
            this.saved.emit(this.passForm.value.password);
        } catch (e) {
            // 403 error already catch by the interceptor
            if (e.status !== 403) {
                this.notifierService.error(e);
            }
        } finally {
            this.saving = false;
        }
    }

    private addOldPasswordControl() {
        this.passForm.addControl('oldpassword', this.fb.control(null, [Validators.required]));
    }

}
