import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'faIcon'
})
export class FaIconPipe implements PipeTransform {

    transform(value: string, ...args: string[]): unknown {
        return `<i class="far fa-${value || args[0]}"></i>`;
    }

}
