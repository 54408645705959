import {Component, OnInit} from '@angular/core';
import {AuthService} from '@cseemploye/app/auth/services/auth.service';
import {CseEmploye} from '@mcv/core';
import parseJSON from 'date-fns/parseJSON';
import addMonths from 'date-fns/addMonths';

@Component({
    selector: 'mcv-outsider-warning',
    templateUrl: './outsider-warning.component.html',
    styleUrls: ['./outsider-warning.component.scss']
})
export class OutsiderWarningComponent implements OnInit {
    currentUser: CseEmploye;
    outsider_end: Date;

    constructor(private authService: AuthService) {
    }

    ngOnInit(): void {
        this.currentUser = this.authService.user;
        this.outsider_end = addMonths(parseJSON(this.currentUser.outsider_start), this.currentUser.outsider_delay);
    }
}
