import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject, concat } from 'rxjs';
import { DeviceTokenService } from '@cseemploye/service/device-token.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { takeLast } from 'rxjs/operators';
import firebase from 'firebase/app';
import 'firebase/messaging';


export interface NotificationMessage {
    from: string;
    priority: string;
    notification: NotificationBody;
}

export interface NotificationBody {
    title: string;
    body: string;
}

@Injectable()
export class MessagingService {

    registered = false;
    token: string;
    currentMessage = new BehaviorSubject<NotificationMessage>(null);

    constructor(private angularFireMessaging: AngularFireMessaging,
                private deviceTokenService: DeviceTokenService,
                private deviceService: DeviceDetectorService) {
        if (firebase.messaging.isSupported()) {
            this.angularFireMessaging.onMessage(
                (_messaging) => {
                    _messaging.onMessage = _messaging.onMessage.bind(_messaging);
                    _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
                }
            );
        }
    }

    requestPermission() {
        if (!firebase.messaging.isSupported()) {
            return;
        }
        this.angularFireMessaging.requestPermission.subscribe(() => {
            this.angularFireMessaging.getToken.subscribe(
                (token) => {
                    this.token = token;
                    concat(
                        this.deviceTokenService.register({
                            deviceId: token,
                            deviceToken: token,
                            os: this.deviceService.os,
                            device: this.deviceService.deviceType,
                            deviceName: this.deviceService.device,
                            language: navigator.language,
                            osVersion: this.deviceService.os_version,
                            timezone: new Date().getTimezoneOffset() / 60,
                            appVersion: 'last version',
                            supportBadge: false,
                            supportSound: false,
                            supportBanner: false
                        }),
                        this.deviceTokenService.login({
                            os: this.deviceService.os,
                            deviceId: token
                        })
                    ).pipe(takeLast(1)).subscribe(value => {
                        this.registered = true;
                    }, error => {
                        this.registered = false;
                    });
                },
                (err) => {
                    console.error('Unable to get permission to notify.', err);
                }
            );
        });

    }

    turnOffNotification() {
        if (!firebase.messaging.isSupported()) {
            return;
        }
        if (this.token != null) {
            this.angularFireMessaging.deleteToken(this.token);
            concat(
                this.deviceTokenService.logout({
                    os: this.deviceService.os,
                    deviceId: this.token
                }),
                this.deviceTokenService.unregister({
                    os: this.deviceService.os,
                    deviceId: this.token
                })
            ).pipe(takeLast(1)).subscribe(value => {
                this.registered = false;
            }, error => {
                this.registered = true;
            });
        }
    }

    receiveMessage() {
        if (!firebase.messaging.isSupported()) {
            return;
        }
        this.angularFireMessaging.messages.subscribe(
            (payload: any) => {
                const data = <NotificationMessage>(payload);
                this.currentMessage.next(data);
            });
    }
}
