import {Pipe, PipeTransform} from '@angular/core';
import {Country} from '@mcv/core';

@Pipe({
    name: 'country'
})
export class CountryPipe implements PipeTransform {
    country = Country;

    transform(value: string, ...args: unknown[]): string {
        const country = this.country.find(c => c.ccode === value);
        return country ? country.cname : null;
    }

}
