import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BASEURL } from '@mcv/config';
import { BaseRepository, CseEmployeWallet, CseEmployeBalance, Result, SingleResult } from '@mcv/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CseEmployeWalletService extends BaseRepository<CseEmployeWallet> {
    protected model = 'cse-employe-wallet';

    constructor(protected httpClient: HttpClient,
                @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl);
    }

    getCseEmployeBalanceByWalletType(): Observable<SingleResult<CseEmployeBalance[]>> {
        return this.httpClient.get<SingleResult<CseEmployeBalance[]>>(`${this.baseUrl}/${this.model}/balance`);
    }

    getWalletsByEmploye(id) {
        return this.httpClient.get<Result<CseEmployeWallet>>(`${this.baseUrl}/${this.model}/?cse_employe_id=${id}&withWalletType=1`);
    }

    getCseEmployeWalletById(cseEmployeWalletId: number): Observable<any> {
        return this.httpClient.get<any>(`${this.baseUrl}/${this.model}/?id=${cseEmployeWalletId}&withWalletType=1`);
    }

}
