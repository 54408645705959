export interface Transaction {
    cse_employe_id: string;
    currency: string;
    tm: string;
    online: boolean;
    label: string;
    icon: string;
    infos: string;
    extras: string;
    amount: number;
    cse_employe_justificatif: CseEmployeJustificatif;
    cse_employe_justificatif_required: boolean;
    publicPaymentId: string;
    cardtransaction: boolean;
    walletCreditBalance: number;
    walletDebitBalance: number;
    payin?: any;
    isCreditTransfer?: boolean
    transferType?: number;
}

export interface CseEmployeJustificatif {
    id: number;
    public_id: string;
    content_type: string;
    cse_employe_id: number;
    date_create: string;
    date_update: string;
    filename: string;
    publicURL: string;
    state: string;
    state_comment: string;
    trz_cardtransactionId: string;
    trz_paymentId: string;
}

export enum JUSTIFICATIFSTATE {
    new = "new",
    ok = "ok",
    ko = "ko",
    hidden = "hidden",
}
