import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BASEURL } from '@mcv/config';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '@cseemploye/environments/environment';
import { AppConfig, CseEmploye, SingleResult } from '@mcv/core';
import { AuthService } from './auth.service';
import { HeapService } from '@mcv/util-analytics';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationsService {
    model = 'App';
    environnement = environment;

    constructor(
        private httpClient: HttpClient,
        @Inject(BASEURL) public baseUrl: string,
        private authService: AuthService,
        private heapService: HeapService
    ) {
        this.loadAppConfig();
        if (this.environnement.HEAP_APP_ID?.CSE_EMPLOYE) {
            this.heapService.load(this.environnement.HEAP_APP_ID.CSE_EMPLOYE);
        }
    }

    loadAppConfig(): Observable<SingleResult<AppConfig>> {
        return this.httpClient
            .get<SingleResult<AppConfig>>(`${this.baseUrl}/cse-employe/${this.model}/getConfig`)
            .pipe(
                map(r => r.data),
                tap((c) => {
                    const user = c.user as CseEmploye;

                    this.environnement.client_env = c.env;
                    this.authService.user = user;

                    if (this.environnement.HEAP_APP_ID?.CSE_EMPLOYE) {
                        this.heapService.identify(user.public_id);
                        this.heapService.addUserProperties({
                            'cse_employe.public_id': user.public_id,
                            'cse_employe.cse_public_id': user.cse_public_id,
                            'cse_employe.state': user.state,
                            'cse_employe.onboarding_state': user.onboarding_state
                        });
                    }
                }),
                catchError(_ => {
                    this.authService.logout();
                    return of(null);
                })
            );
    }
}
