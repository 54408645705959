import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BASEURL } from '@mcv/config';
import { AuthResult, CseEmploye, OnboardingResult, SingleResult } from '@mcv/core';
import { Observable } from 'rxjs';

export interface PreboardingResult {
    jwt: AuthResult,
    cseEmploye: CseEmploye
    step: string
}

interface PreboardingResponseStep {
    step: string
}

interface PreboardingNextStepResponse extends PreboardingResponseStep {
    cseEmploye: CseEmploye
}

@Injectable({
    providedIn: 'root'
})
export class ActivationService {
    model = 'cse-employe/activation';

    constructor(private httpClient: HttpClient,
                @Inject(BASEURL) public baseUrl: string) {
    }

    preboarding(id: string, key: string): Observable<SingleResult<PreboardingResult>> {
        return this.httpClient
            .put<SingleResult<PreboardingResult>>(`${this.baseUrl}/${this.model}/preboarding`, {
                id,
                key
            });
    }

    askSendCode(email: string): Observable<any> {
        return this.httpClient.post<SingleResult<any>>(`${this.baseUrl}/${this.model}/askSendCode`, email);
    }

    preboardingDone(id: string): Observable<any> {
        return this.httpClient.post<SingleResult<any>>(`${this.baseUrl}/${this.model}/preboardingDone`, { id });
    }

    onboarding(formValue: any): Observable<SingleResult<OnboardingResult>> {
        return this.httpClient.put<SingleResult<OnboardingResult>>(`${this.baseUrl}/${this.model}/onboarding`, formValue);
    }

    endWithActiveAccount() {
        return this.httpClient.get<SingleResult<OnboardingResult>>(`${this.baseUrl}/${this.model}/endWithActiveAccount`);
    }

    sendCodeBySms(): Observable<any> {
        return this.httpClient.post<any>(`${this.baseUrl}/${this.model}/sendCodeBySms`, null);
    }

    confirmCode(code: number): Observable<any> {
        return this.httpClient.put<any>(`${this.baseUrl}/${this.model}/confirmCode`, { code });
    }

    end(password: string): Observable<any> {
        return this.httpClient.put<any>(`${this.baseUrl}/${this.model}/end`, { password });
    }

    //region Gestion des justificatifs en mode non authentifié

    justificatif(publicId: string): Observable<SingleResult<any>> {
        return this.httpClient.get<SingleResult<any>>(`${this.baseUrl}/exchange/justificatif/${publicId}`);
    }

    reclamation(publicId: string): Observable<SingleResult<any>> {
        return this.httpClient.get<SingleResult<any>>(`${this.baseUrl}/exchange/reclamation/${publicId}`);
    }

    abo(publicId: string, abo: boolean): Observable<SingleResult<any>> {
        return this.httpClient.put<SingleResult<any>>(`${this.baseUrl}/exchange/abo/${publicId}`, { abo });
    }

    preboardingSetPersonalInformation(userData: object): Observable<SingleResult<PreboardingNextStepResponse>> {
        return this.httpClient.put<SingleResult<PreboardingNextStepResponse>>(
            `${this.baseUrl}/${this.model}/preboardingSetPersonalInformation`,
            { userData }
        );
    }

    preboardingSetAddress(userData: object): Observable<SingleResult<PreboardingNextStepResponse>> {
        return this.httpClient.put<SingleResult<PreboardingNextStepResponse>>(
            `${this.baseUrl}/${this.model}/preboardingSetAddress`,
            { ...userData }
        );
    }

    preboardingBackToPersonalInformation(): Observable<SingleResult<PreboardingResponseStep>> {
        return this.httpClient.post<SingleResult<PreboardingResponseStep>>(
            `${this.baseUrl}/${this.model}/preboardingBackToPersonalInformation`,
            {}
        );
    }

    preboardingBackToAddress(): Observable<SingleResult<PreboardingResponseStep>> {
        return this.httpClient.post<SingleResult<PreboardingResponseStep>>(
            `${this.baseUrl}/${this.model}/preboardingBackToAddress`,
            {}
        );
    }

    //endregion
}
