import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'sortByDate'
})
export class SortByDatePipe implements PipeTransform {

    transform(value: any[], ...args: string[]): any {
        const field = args[0];
        const immutableArray = [...value];
        return immutableArray.sort((a, b) => new Date(a[field]).getTime() - new Date(b[field]).getTime());
    }
}
