<div class="modal-header">
    <h4 class="modal-title align-self-center" id="motDePasseOublie">Mot de passe oublié ?</h4>
    <button (click)="modalRef.hide()" aria-label="Close" class="close" type="button">
        <i aria-hidden="true" class="far fa-times"></i>
    </button>
</div>
<div class="modal-body">
    <ng-container *ngIf="step === 1">
        <form (ngSubmit)="sendLostPassword()" [formGroup]="lostPasswordForm">
            <div class="form-group">
                <p><small>Entrez l'adresse e-mail professionnelle indiquée au moment de la création de votre compte. Vous
                    recevrez un lien afin de réinitialiser votre mot de passe.</small></p>
            </div>
            <div class="form-group">
                <label for="motdepass-email">Email professionnel</label>
                <input class="form-control"
                       formControlName="email"
                       id="motdepass-email"
                       name="email"
                       placeholder="nom.prenom@societe.com"
                       type="text">
            </div>
            <div class="form-group mt-5">
                <div class="float-right">
                    <button (click)="modalRef.hide()" class="btn btn-link" type="button">Annuler</button>
                    <button [disabled]="!lostPasswordForm.valid || loading"
                            [promiseBtn]="loading"
                            class="btn btn-primary"
                            type="submit">
                        Soumettre
                    </button>
                </div>
            </div>
        </form>
    </ng-container>
    <ng-container *ngIf="step === 2">
        <form (ngSubmit)="sendLostPasswordWithTarget()" [formGroup]="lostPasswordForm">
            <div class="form-group">
                Plusieurs emails sont paramétrés, sur lequel de ces emails voulez-vous recevoir le lien de
                réinitialisation :<br/>
                <div class="form-check mt-4">
                    <input checked
                           class="form-check-input"
                           formControlName="target"
                           id="exampleRadios1"
                           type="radio" value="email">
                    <label class="form-check-label" for="exampleRadios1">
                        {{anonymousEmails.email}}
                    </label>
                </div>
                <div class="form-check mt-2">
                    <input class="form-check-input"
                           formControlName="target"
                           id="exampleRadios2"
                           type="radio"
                           value="email_alt">
                    <label class="form-check-label" for="exampleRadios2">
                        {{anonymousEmails.email_alt}}
                    </label>
                </div>
            </div>
            <div class="form-group mt-5">
                <div class="float-right">
                    <button (click)="modalRef.hide()" class="btn btn-link" type="button">Annuler</button>
                    <button [disabled]="!lostPasswordForm.valid || loading"
                            [promiseBtn]="loading"
                            class="btn btn-primary"
                            type="submit">
                        Soumettre
                    </button>
                </div>
            </div>
        </form>
    </ng-container>
</div>
