<div class="container pt-2">
    <!-- Category section header -->
    <ng-container *ngFor="let catalogueItem of catalogueItems">
        <div class="row align-items-center mb-2 h3 mt-3">
            <div class="col-4">
                <img class="img-fluid mx-auto d-block" src="{{baseUrl}}{{catalogueItem.logo}}"
                     style="max-height: 100px"/>
            </div>
            <div class="col-8 text-primary-alt">
                <div class="row">
                    {{catalogueItem.title}}
                </div>
            </div>
        </div>
        <div class="row-12 text-center text-primary h4 pb-2">Compatible avec tous les</div>
        <div *ngFor="let target of catalogueItem.target" class="row-12">
            <!-- Mobile -->
            <div class="d-block d-md-none d-none">
                <div class="row-12 text-center">
                    <span class="text-primary-alt h4">{{target.name}}</span>
                </div>
                <div class="row-12 text-center pb-2">
                    {{target.desc}}
                </div>
                <div class="row p-2">
                    <img class="img-fluid mx-auto d-block" src="{{baseUrl}}{{target.merchangeImage}}"
                         style="max-height: 400px"/>
                </div>
            </div>
            <!-- Desktop -->
            <div class="d-none d-md-block d-xl-block">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-4 text-center">
                            <span class="text-primary-alt h4">{{target.name}}</span>
                            <div class="row-12 p-2">
                                {{target.desc}}
                            </div>
                        </div>
                        <div class="col-8">
                            <img class="img-fluid mx-auto d-block" src="{{baseUrl}}{{target.merchangeImage}}"
                                 style="max-height: 400px"/>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Category section footer -->
            <div class="text-right pb-3 pt-1">
                <small class="text-primary">… et bien sûr, tous les autres !</small>
            </div>
        </div>
    </ng-container>
</div>
