<ng-container [formGroup]="formGroup">
    <div class="form-row">
        <div class="form-group col-md-4">
            <label>Genre<small>*</small></label>
            <div>
                <div class="custom-control custom-radio custom-control-inline male">
                    <input class="custom-control-input"
                           formControlName="title"
                           id="title-male"
                           name="title"
                           type="radio"
                           value="M">
                    <label class="custom-control-label" for="title-male">
                        <i class="far fa-male mr-1"></i>Homme
                    </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline female">
                    <input class="custom-control-input"
                           formControlName="title"
                           id="title-female"
                           name="title"
                           type="radio"
                           value="MME">
                    <label class="custom-control-label" for="title-female">
                        <i class="far fa-female mr-1"></i>Femme
                    </label>
                </div>
            </div>
            <val-errors controlName="title"></val-errors>
        </div>
        <div class="form-group col-md-4">
            <label for="firstname">Prénom<small>*</small></label>
            <input class="form-control"
                   data-cy="firstname"
                   formControlName="firstname"
                   id="firstname"
                   name="firstname"
                   type="text">
            <val-errors controlName="firstname"></val-errors>
        </div>
        <div class="form-group col-md-4">
            <label for="lastname">Nom<small>*</small></label>
            <input class="form-control"
                   data-cy="lastname"
                   formControlName="lastname"
                   id="lastname"
                   name="lastname"
                   type="text">
            <val-errors controlName="lastname"></val-errors>
        </div>
    </div>
    <div class="form-row">
        <div *ngIf="showBirthday" class="form-group col-lg-2 col-md-4 col-sm-6">
            <label for="firstname">Date de naissance<small>*</small></label>
            <mcv-datepicker data-cy="birthday"
                            formControlName="birthday"></mcv-datepicker>
            <val-errors controlName="birthday"></val-errors>
        </div>
        <div class="form-group col-md-4 col-sm-6" [ngClass]="{'col-lg-4': showBirthday === false, 'col-lg-2': showBirthday }" >
            <label>Pays de naissance<small>*</small></label>
            <mcv-country-select data-cy="birthCountry" formControlName="birthCountry"></mcv-country-select>
            <val-errors controlName="birthCountry"></val-errors>
        </div>
        <div class="form-group col-lg-3 col-md-4 col-sm-6">
            <label for="placeOfBirth">Lieu de naissance<small>*</small></label>
            <input class="form-control"
                   data-cy="placeOfBirth"
                   formControlName="placeOfBirth"
                   id="placeOfBirth"
                   name="placeOfBirth"
                   type="text">
            <val-errors controlName="placeOfBirth"></val-errors>
        </div>
        <div class="form-group col-lg-2 col-md-4 col-sm-6">
            <label>Nationalité<small>*</small></label>
            <mcv-country-select data-cy="nationality" formControlName="nationality">
            </mcv-country-select>
            <val-errors controlName="nationality"></val-errors>
        </div>
        <div class="form-group col-lg-3 col-md-4 col-sm-6">
            <label [popover]="popTemplate"
                   for="specifiedUSPerson"
                   popoverTitle="Définition d'une US Person"
                   triggers="mouseenter:mouseleave">Êtes-vous une US Person ?<small>*</small></label>
            <mcv-yesno-select (change)="confirmUsPerson($event)"
                              formControlName="specifiedUSPerson"
                              id="specifiedUSPerson"
            ></mcv-yesno-select>
            <val-errors controlName="specifiedUSPerson"></val-errors>
        </div>
    </div>
</ng-container>
<ng-template #popTemplate>
    <ul>
        <li>Lieu de naissance aux Etats-Unis</li>
        <li>ou nationalité Américaine</li>
        <li>ou adresse de résidence aux Etats-Unis</li>
        <li>ou adresse connue portant la mention « A l’Attention de » ou « Poste restante »</li>
        <li>ou numéro de téléphone aux Etats-Unis</li>
        <li>ou procuration donnée à une personne physique ayant une adresse aux Etats Unis</li>
    </ul>
</ng-template>
