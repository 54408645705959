export interface CseEmployeTopup {
    id: number;
    amount: number;
    currency: string;
    date_create: string;
    date_update: string;
    state: string;
    hash: string;
    transaction: string;
    transaction_state: TransactionState;
    error: string;
    error_code: number;
    uri: string;
    cse_employe_id: number;
    redirectUrl?: string;
    source: string;
}

export enum TransactionState {
    COMPLETED = 'completed',
    PENDING = 'pending',
    DECLINED = 'declined',
    ERROR = 'error',
    FORWARDING = 'forwarding'
}
