import {AsyncPipe, registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {ErrorHandler, Injector, LOCALE_ID, NgModule, Provider} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {environment} from '@cseemploye/environments/environment';
import {BASEURL} from '@mcv/config';
import {UiModule} from '@mcv/ui';
import {IntercomModule} from 'ng-intercom';
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from 'ngx-google-analytics';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthModule} from './auth/auth.module';
import {SharedModule} from './shared/shared.module';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {AngularFireModule} from '@angular/fire';
import {AngularFireMessagingModule} from '@angular/fire/messaging';
import {AngularFireRemoteConfigModule, DEFAULTS, SETTINGS} from '@angular/fire/remote-config';
import {MessagingService} from '@cseemploye/service/messaging.service';
import {BugsnagErrorHandler} from '@bugsnag/plugin-angular';
import {ServiceLocator} from '@mcv/coreservices';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import {httpInterceptorProviders} from '@mcv/core';

registerLocaleData(localeFr);

export function errorHandlerFactory() {
    return new BugsnagErrorHandler();
}

const providers: Provider[] = [
    {provide: ErrorHandler, useFactory: errorHandlerFactory},
    {provide: LOCALE_ID, useValue: 'fr-FR'},
    {provide: BASEURL, useValue: environment.baseUrl},
    {provide: DEFAULTS, useValue: environment.firebase.remoteConfig.defaults},
    {
        provide: SETTINGS,
        useValue: environment.firebase.remoteConfig.settings
    },
    MessagingService,
    AsyncPipe
];
if (environment.client_env === 'dev' || environment.client_env === 'local') {
    providers.push(httpInterceptorProviders);
}


@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        AuthModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        SharedModule,
        NgxGoogleAnalyticsModule.forRoot(environment.G_TAG),
        NgxGoogleAnalyticsRouterModule,
        IntercomModule.forRoot({
            appId: environment.INTERCOM, // from your Intercom config
            updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
        }),
        UiModule,
        AngularSvgIconModule.forRoot(),
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireRemoteConfigModule,
        GooglePlaceModule
    ],
    providers: providers,
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {

    constructor(private injector: Injector) {    // Create global Service Injector.
        ServiceLocator.injector = this.injector;
    }
}
