import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {CseEmployeService} from '@mcv/coreservices';
import {CseEmploye} from '@mcv/core';
import {Subscription} from 'rxjs';

@Component({
    selector: 'mcv-profile-abo',
    templateUrl: './profile-abo.component.html',
    styleUrls: ['./profile-abo.component.scss']
})
export class ProfileAboComponent implements OnInit, OnDestroy {
    @Input() cseEmploye: CseEmploye;
    aboForm: FormGroup = this.fb.group({
        public_id: [],
        abo_use_email_alt: [],
        abo_email: [],
        abo_perso: [],
        abo_mobile: []
    });
    subscription: Subscription = new Subscription();

    constructor(private fb: FormBuilder,
                private cseEmployeService: CseEmployeService) {
    }

    ngOnInit(): void {
        this.aboForm.patchValue(this.cseEmploye);
        this.subscription.add(
            this.aboForm.valueChanges.subscribe(_ => {
                this.cseEmployeService.add(this.aboForm.value).toPromise();
            })
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

}
