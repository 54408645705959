import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ConfirmationModalComponent } from '@mcv/ui';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'mcv-user-profile-info',
    templateUrl: './user-profile-info.component.html',
    styleUrls: ['./user-profile-info.component.scss']
})
export class UserProfileInfoComponent implements OnInit {
    @Input() formGroup: FormGroup;
    showBirthday = false;

    constructor(private modalService: BsModalService) {
    }

    ngOnInit(): void {
        if (this.formGroup.get('birthday')) {
            this.showBirthday = true;
        }
    }

    nosort(_): number {
        return 0;
    }

    async confirmUsPerson(selected: boolean) {
        if (selected) {
            const modal = this.modalService.show(ConfirmationModalComponent, {
                class: 'modal-dialog-centered modal-lg',
                initialState: {
                    active: true,
                    title: 'Merci de confirmer',
                    body: 'Si vous vous déclarez comme une US Person, vous devrez nous fournir le formulaire W9 ou NIF réclamé par l\'administration fiscale.'
                }
            });

            const sub = await (<ConfirmationModalComponent>modal.content).onClose.subscribe(result => {
                if (!result) {
                    this.formGroup.patchValue({ specifiedUSPerson: false });
                }
                sub.unsubscribe();
            });
        }
    }
}
