<div class="container-fluid p-0 h-100" style="overflow: auto">
    <div class="d-flex flex-column h-100 justify-content-between">
        <div class="text-center text--title__font">
            Bienvenue sur votre espace <b>macartevacances</b>
        </div>
        <img class="ml-auto mt-5"
             src="{{baseUrl}}/img/carousel/Bienvenue@2x.png"
             style="width: 300px;height: 175px;"/>

        <div class="flex-grow-1 text-center text--line__font align-items-center d-flex">
            Découvrez comment utiliser votre carte de paiement ainsi que l’ensemble des fonctionnalités
            macartevacances
        </div>
    </div>
</div>
