import {Component, Input, OnInit} from '@angular/core';
import {JUSTIFICATIFSTATE, Transaction} from '@mcv/core';

@Component({
    selector: 'mcv-transaction-detail-list',
    templateUrl: './transaction-detail-list.component.html',
    styleUrls: ['./transaction-detail-list.component.scss']
})
export class TransactionDetailListComponent implements OnInit {
    @Input() transaction: Transaction;

    constructor() {
    }

    ngOnInit(): void {
    }

    refreshData() {
        this.transaction.cse_employe_justificatif.state = JUSTIFICATIFSTATE.new;
    }
}
