import {Pipe, PipeTransform} from '@angular/core';
import {Transaction} from '@mcv/core';

@Pipe({
    name: 'getSolde'
})
export class GetSoldePipe implements PipeTransform {

    transform(transaction: Transaction, ...args: unknown[]): number {
        if (transaction.isCreditTransfer === true) {
            if (transaction.transferType === 1) {
                return transaction.walletCreditBalance - transaction.amount;
            } else {
                return transaction.walletCreditBalance;
            }
        } else {
            if (transaction.transferType === 1) {
                return transaction.walletDebitBalance - transaction.amount;
            } else {
                return transaction.walletDebitBalance;
            }
        }
    }
}

/*
IF transaction.transactionType == ‘Transfer’
GET transfer WHERE transfer.transferId = transaction.foreignId
IF transaction.walletCreditId == cse_employe.trz_walletId
{
  IF transfer.transferTypeId == 1 THEN walletCreditBalance-amount;
  IF transfer.transferTypeId == 2 THEN walletCreditBalance;
} else {
  IF transfer.transferTypeId == 1 THEN walletDebitBalance-amount;
  IF transfer.transferTypeId == 2 THEN walletDebitBalance;
}
 */
