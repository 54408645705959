<div class="container-fluid p-0 h-100" style="overflow: auto">

    <div class="d-flex flex-column h-100">

        <div class="d-flex flex-grow-0 mb-2 mt-2 ml-auto mr-auto">
            <div class="text-center text--title__font">
                Fonctionnalités
            </div>
        </div>

        <div class="d-flex flex-shrink-1 justify-content-center my-3">
            <div class="subtitle2 text-center">
                L’application macartevacances vous offre de <b>nombreuses possibilités</b>
            </div>
        </div>

        <div class="flex-fill d-flex">
            <div class="d-flex align-items-center">
                <div class="d-flex flex-row mr-auto ml-auto item-width">
                    <img class="mr-3" src="{{baseUrl}}/img/carousel/ordinateur_clr.svg">
                    <div class="d-flex flex-column">
                        <div class="text-left item-description">
                            Consulter votre solde en temps réel et votre historique de transactions
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex-fill d-flex">
            <div class="d-flex align-items-center">
                <div class="d-flex flex-row mr-auto ml-auto item-width">
                    <img class="mr-3" src="{{baseUrl}}/img/carousel/carte_clr.svg">
                    <div class="d-flex flex-column">
                        <div class="text-left item-description">
                            Ajouter de l’argent pour compléter une dépense
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex-fill d-flex">
            <div class="d-flex align-items-center">
                <div class="d-flex flex-row mr-auto ml-auto item-width">
                    <img class="mr-3" src="{{baseUrl}}/img/carousel/hand_clr.svg">
                    <div class="d-flex flex-column">
                        <div class="text-left item-description">
                            Faire opposition à votre carte en cas de vol ou de perte
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex-fill d-flex align-items-center pb-4">
            <div class="d-flex align-items-center">
                <div class="d-flex flex-row align-items-center mr-auto ml-auto item-width">
                    <img class="mr-3" src="{{baseUrl}}/img/carousel/wallet_clr.svg">
                    <div class="d-flex flex-column">
                        <div class="text-left item-description">
                            Budget valable indéfiniment
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
