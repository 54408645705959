import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {BASEURL} from '@mcv/config';

@Component({
    selector: 'mcv-step6',
    templateUrl: './step6.component.html',
    styleUrls: ['./step6.component.scss']
})
export class Step6Component implements OnInit {
    @Output() nextStep: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(@Inject(BASEURL) public baseUrl: string) {
    }

    ngOnInit(): void {
    }

}
