import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LogasComponent } from '@cseemploye/app/auth/pages/logas/logas.component';
import { LogoutComponent } from '@cseemploye/app/auth/pages/logout/logout.component';
import { LoginComponent } from './pages/login/login.component';
import { IndexComponent } from './pages/index/index.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ResetLinkExpiredComponent } from '@cseemploye/app/auth/pages/reset-link-expired/reset-link-expired.component';
import { ResetLinkInvalidComponent } from '@cseemploye/app/auth/pages/reset-link-invalid/reset-link-invalid.component';

const routes: Routes = [
    {
        path: 'auth',
        component: IndexComponent,
        children: [
            { path: 'login', component: LoginComponent },
            { path: 'logout', component: LogoutComponent },
            { path: 'password', component: ResetPasswordComponent },
            { path: 'logas/:id', component: LogasComponent },
            { path: 'reset-link-expired', component: ResetLinkExpiredComponent },
            { path: 'reset-link-invalid', component: ResetLinkInvalidComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthRoutingModule {
}
